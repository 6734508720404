import { Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { Table, Button, Form, Image, Nav } from 'react-bootstrap';
import "../table.css";
import editIcon from '../assets/icons/edit.png';


const DataReport = () => {

  const reports = [
    { name: "TDM Report", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit", workspaceId: 26180, tenantId: 57400 },
    { name: "Membership Report", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit", workspaceId: 26180, tenantId: 57400 },
    { name: "Dubai Startup Report", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit", workspaceId: 26180, tenantId: 57400 },
    { name: "Customer 360 Report", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit", workspaceId: 26180, tenantId: 57400 },
    { name: "Business Council Report", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit", workspaceId: 26180, tenantId: 57400 },
    { name: "Business Group Report", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit", workspaceId: 26180, tenantId: 57400 },
  ];

  

  return (
    <>
      <div className="content page-wrapper">
        <div className="page-content">

          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Card.Header className="bg-white border-0 p-0">
                  <div class="d-flex align-items-center">
                    <div>
                      <h5 class="mb-0 fw-700">Data Report</h5>
                    </div>
                    <div class="ms-auto">
                      <Button variant="" style={{ fontSize: '14px' }} href="./AddDataReport"><i class="fa-solid fa-circle-plus text-white"></i> Add Report</Button>

                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="p-0">
                  <Row>
                    <Col lg={12}>
                    <Table bordered hover responsive className="mt-3">
      <thead className="table">
        <tr>
          <th className="pl-0 no-wrap-header">
            <Form.Check type="checkbox" />
          </th>
          <th className="no-wrap-header">File Name</th>
          <th className="no-wrap-header">File Description</th>
          <th className="no-wrap-header">Blob Location</th>
          <th className="no-wrap-header">Blob Key</th>
          <th className="no-wrap-header">Workspace ID</th>
          <th className="no-wrap-header">Tenant ID</th>
          <th className="no-wrap-header">Action</th>
        </tr>
      </thead>
      <tbody>
        {reports.map((report, index) => (
          <tr key={index}>
            <td>
              <Form.Check type="checkbox" />
            </td>
            <td  className="no-wrap-header">
              <Nav.Link className="text-decoration-underline">
                {report.name}
              </Nav.Link>
            </td>
            <td>
              <p className="overflow-description">{report.description}</p>
            </td>
            <td></td>
            <td></td>
            <td>{report.workspaceId}</td>
            <td>{report.tenantId}</td>
            <td>
              <div className="d-flex align-items-center">
                <Nav.Link variant="link" className="me-3">
                  <Image style={{ width: '14px' }} src={editIcon} />
                </Nav.Link>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

    
    </>
  );
};

export default DataReport;
