import React from "react";
import { Card, Button, Row, Col, Image, Nav } from "react-bootstrap";
import "../../../src/dashboard.css";
import { useNavigate } from "react-router-dom";
const CustomCardForSunscribedReport = ({
  id,
  title,
  imageSrc,
  previewSrc,
  description,
  previewAlt = "Click to Preview",
  buttonLabel = "Open",
  buttonLink = "#",
}) => {
  const navigate = useNavigate();
  return (
    <Col lg={4} className="d-flex mb-4">
      <Card className="custom-card inner-card">
        <Card.Body className="p-0">
          <Row>
            <Col lg={12}>
              <div className="card-header-section">
                <Card.Title className="card-title custom-card-title">
                  {title}
                </Card.Title>
              </div>

              <div className="card-image">
                <Image src={imageSrc} alt="Report" className="image-hover" />
                <div className="preview-link">
                  <Image
                    src={previewSrc}
                    alt={previewAlt}
                    className="preview-image"
                  />
                  <Nav.Link
                    onClick={(e)=>{e.preventDefault(); navigate({ pathname: "/ViewReport/"+ id})}}
                    className="text-danger p-0 fw-600"
                  >
                    {previewAlt}
                  </Nav.Link>
                </div>
              </div>

              <Card.Text className="card-text">{description}</Card.Text>

              <div className="card-footer-section mt-0">
                <Button
                  className="btn"
                  href={buttonLink}
                  style={{ fontSize: "12px" }}
                >
                  {buttonLabel}
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default CustomCardForSunscribedReport;
