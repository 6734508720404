import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function authHeader() {
  const token = localStorage.getItem("token");
  if (token) {
    return { Authorization: "Bearer " + token };
  } else {
    return {};
  }
}
