import React, { useState } from "react";
import "../dashboard.css";
import { Row, Form, Breadcrumb } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";

const Settings = () => {
  return (
    <>
      <div className="content page-wrapper">
        <div className="page-content">
          <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <Breadcrumb className="mb-0">
              <Breadcrumb.Item className="fw-700">
                <strong>Account Setting</strong>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Row>
                  <Col lg={12}>
                    <Form>
                      <div className="form-inner position-relative p-3 mt-3 pt-4 shadow-none">
                        <Row>
                          <Col lg={12}>
                            <h5
                              style={{
                                fontSize: "14px",
                                background: "#fff",
                                padding: "0 0px",
                              }}
                              className="text-12 mb-4 overlay-title"
                            >
                              Change Password
                            </h5>
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="mb-3">
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Change Password
                              </Form.Label>
                              <Form.Control
                                type="password"
                                name="ChangePassword"
                                className="custom-field"
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={4}>
                            <Form.Group className="mb-3">
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                New Password
                              </Form.Label>
                              <Form.Control
                                type="password"
                                name="NewPassword"
                                className="custom-field"
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={4}>
                            <Form.Group className="mb-2">
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Confirm Password
                              </Form.Label>
                              <Form.Control
                                type="password"
                                name="ConfirmPassword"
                                className="custom-field"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      <Row>
                        <Col lg={6}>
                          <div className="my-4 mb-2">
                            <Button className="btn-light me-2">Cancel</Button>
                            <Button
                              style={{ fontSize: "14px" }}
                              className="btn"
                            >
                              Save
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Settings;
