import axios from "axios";
import { 
  CREATE_REPORT_URL, 
  GET_REPORT_BY_ID_URL, 
  UPDATE_REPORT_URL, 
  DELETE_REPORT_URL, 
  LIST_REPORTS_URL 
} from "./apiConfig";
import authHeader from "./AuthHeader";

class ReportService {
  createReport(reportData) {
    return axios.post(CREATE_REPORT_URL, reportData).then((response) => {
      return response.data;
    });
  }

  getReportById(id) {
    return axios.post(GET_REPORT_BY_ID_URL, { id }).then((response) => {
      return response.data;
    });
  }

  updateReport(reportData) {
    return axios.put(UPDATE_REPORT_URL, reportData).then((response) => {
      return response.data;
    });
  }

  deleteReport(id) {
    return axios.delete(DELETE_REPORT_URL.replace("{id}", id)).then((response) => {
      return response.data;
    });
  }

  listReports() {
    return axios.get(LIST_REPORTS_URL,{
      headers: authHeader()
    }).then((response) => {
      return response.data;
    });
  }
}

export default new ReportService();
