const API_BASE_URL = `http://3.109.10.127:8080/subscription-platform-mw`;

export const AUTHENTICATION_URL =  API_BASE_URL+`/Authentication`;
export const REGISTRATION_URL = API_BASE_URL+`/CreateUpdateUser`;
export const GET_USER_BY_ID_URL = `${API_BASE_URL}/GetUserById`;
export const GET_USER_BY_EMAIL_URL = `${API_BASE_URL}/GetUserByEmail`;
export const LIST_USER_URL = `${API_BASE_URL}/ListUser`;
export const FORGOTPASSWORD_URL = `${API_BASE_URL}/ForgotPassword`;
export const GENERATE_OTP_URL = `${API_BASE_URL}/ForgotPassword/generate-otp`;
export const CREATE_PRODUCT_URL = `${API_BASE_URL}/CreateProduct`;
export const GET_PRODUCT_BY_ID_URL = `${API_BASE_URL}/GetProductById`;
export const LIST_PRODUCTS_URL = `${API_BASE_URL}/ListProducts`;
export const UPDATE_PRODUCT_URL = `${API_BASE_URL}/UpdateProduct`;
export const DELETE_PRODUCT_URL = `${API_BASE_URL}/DeleteProduct/{id}`;
export const CREATE_FEEDBACK_URL = `${API_BASE_URL}/CreateFeedback`;
export const GET_FEEDBACK_BY_ID_URL = `${API_BASE_URL}/GetFeedbackById`;
export const LIST_FEEDBACK_URL = `${API_BASE_URL}/ListFeedback`;
export const UPDATE_FEEDBACK_URL = `${API_BASE_URL}/UpdateFeedback`;
export const DELETE_FEEDBACK_URL = `${API_BASE_URL}/DeleteFeedback/{id}`;
export const CREATE_SUBSCRIPTION_URL = `${API_BASE_URL}/CreateSubscription`;
export const GET_SUBSCRIPTION_BY_ID_URL = `${API_BASE_URL}/GetSubscriptionById`;
export const LIST_SUBSCRIPTIONS_URL = `${API_BASE_URL}/ListSubscriptions`;
export const UPDATE_SUBSCRIPTION_URL = `${API_BASE_URL}/UpdateSubscription`;
export const DELETE_SUBSCRIPTION_URL = `${API_BASE_URL}/DeleteSubscription/{id}`;
export const CREATE_REPORT_URL = `${API_BASE_URL}/CreateReport`;
export const GET_REPORT_BY_ID_URL = `${API_BASE_URL}/GetReportById`;
export const LIST_REPORTS_URL = `${API_BASE_URL}/ListReports`;
export const UPDATE_REPORT_URL = `${API_BASE_URL}/UpdateReport`;
export const DELETE_REPORT_URL = `${API_BASE_URL}/DeleteReport/{id}`;
export const CREATE_RESEARCH_DATA_URL = `${API_BASE_URL}/CreateResearchData`;
export const GET_RESEARCH_DATA_BY_ID_URL = `${API_BASE_URL}/GetResearchDataById`;
export const LIST_RESEARCH_DATA_URL = `${API_BASE_URL}/ListResearchData`;
export const UPDATE_RESEARCH_DATA_URL = `${API_BASE_URL}/UpdateResearchData`;
export const DELETE_RESEARCH_DATA_URL = `${API_BASE_URL}/DeleteResearchData/{id}`;
export const VERIFYUSER_URL = `${API_BASE_URL}/`;