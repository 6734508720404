// import React from "react";
// import "../dashboard.css";
// import { Row, Form, Image, Button, Nav } from "react-bootstrap";
// import Card from "react-bootstrap/Card";
// import Col from "react-bootstrap/Col";
// import reportImage from "../assets/dc-report.png";
// import reportImage2 from "../assets/pdf.png";
// import preview from "../assets/icons/view.png";

// const MySubscription = () => {
//   return (
//     <>
//       <div className="content page-wrapper">
//         <div className="page-content">
//           <Row>
//             <Col lg={12}>
//               <Card className="outer-card">
//                 <Row>
//                   <Col lg={12}>
//                     <div className="card-title-danger d-flex align-items-center mb-4">
//                       <h3 className="outer-card-title">Business Report</h3>
//                       <span>
//                         Your Subscription plan will be valid for{" "}
//                         <strong className="fw-700">3 months.</strong>
//                       </span>
//                     </div>
//                   </Col>
//                 </Row>

//                 <Row>
//                   <Col lg={4} className="d-flex mb-4">
//                     <Form.Check
//                       type="checkbox"
//                       className="checkbox-custom mt-0"
//                     />
//                     <Card className="custom-card inner-card">
//                       <Card.Body className="p-0">
//                         <Row>
//                           <Col lg={12}>
//                             <div className="card-header-section">
//                               <Card.Title className="card-title custom-card-title">
//                                 Trade Data Monitor Report (TDM)
//                               </Card.Title>
//                             </div>

//                             <div className="card-image">
//                               <Image
//                                 src={reportImage}
//                                 alt="Report"
//                                 className="image-hover"
//                               />
//                               <div className="preview-link">
//                                 <Image
//                                   src={preview}
//                                   alt="Click to Preview"
//                                   className="preview-image"
//                                 />
//                                 <Nav.Link className="text-danger p-0 fw-600">
//                                   Click to Preview
//                                 </Nav.Link>
//                               </div>
//                             </div>
//                             <Card.Text className="card-text">
//                               Explore the latest trade trends of the UAE in 20
//                               strategic food products. Our dashboard provides
//                               valuable insights into trade growth, highlights
//                               key monthly trading partners, and showcases the
//                               top global importers and exporters. This tool will
//                               help businesses identify the right partners and
//                               diversify their trade, empowering you to make
//                               informed, strategic decisions in the ever-evolving
//                               food trade landscape.
//                             </Card.Text>

//                             <div className="card-footer-section mt-0">
//                               <Button
//                                 className="btn"
//                                 href="/ViewReport"
//                                 style={{ fontSize: "12px" }}
//                               >
//                                 Open
//                               </Button>
//                             </div>
//                           </Col>
//                         </Row>
//                       </Card.Body>
//                     </Card>
//                   </Col>
//                   <Col lg={4} className="d-flex mb-4">
//                     <Form.Check
//                       type="checkbox"
//                       className="checkbox-custom mt-0"
//                     />
//                     <Card className="custom-card inner-card">
//                       <Card.Body className="p-0">
//                         <Row>
//                           <Col lg={12}>
//                             <div className="card-header-section">
//                               <div>
//                                 <Card.Title className="card-title custom-card-title">
//                                   Country Fact Sheet
//                                 </Card.Title>
//                               </div>
//                             </div>

//                             <div className="card-image">
//                               <Image
//                                 src={reportImage}
//                                 alt="Report"
//                                 className="image-hover"
//                               />
//                               <div className="preview-link">
//                                 <Image
//                                   src={preview}
//                                   alt="Click to Preview"
//                                   className="preview-image"
//                                 />
//                                 <Nav.Link className="text-danger p-0 fw-600">
//                                   Click to Preview
//                                 </Nav.Link>
//                               </div>
//                             </div>
//                             <Card.Text className="card-text">
//                               Our Country Fact Sheet dashboard offers key
//                               economic and business indicators, providing
//                               insights into a country's competitiveness, market
//                               size, tax environment, and other business
//                               opportunities. Whether you're looking to enter new
//                               markets or deepen existing trade ties, the
//                               dashboard provides the critical data you need to
//                               understand the economic landscape and make
//                               informed business decisions. Empower your strategy
//                               with tailored insights into the global
//                               marketplace.{" "}
//                             </Card.Text>
//                           </Col>
//                           <div className="card-footer-section mt-0">
//                             <Button
//                               className="btn"
//                               style={{ fontSize: "12px" }}
//                             >
//                               Open
//                             </Button>
//                           </div>
//                         </Row>
//                       </Card.Body>
//                     </Card>
//                   </Col>
//                   <Col lg={4} className="d-flex mb-4">
//                     <Form.Check
//                       type="checkbox"
//                       className="checkbox-custom mt-0"
//                     />
//                     <Card className="custom-card inner-card">
//                       <Card.Body className="p-0">
//                         <Row>
//                           <Col lg={12}>
//                             <div className="card-header-section">
//                               <div>
//                                 <Card.Title className="card-title custom-card-title">
//                                   Business Analytics
//                                 </Card.Title>
//                               </div>
//                             </div>

//                             <div className="card-image">
//                               <Image
//                                 src={reportImage}
//                                 alt="Report"
//                                 className="image-hover"
//                               />
//                               <div className="preview-link">
//                                 <Image
//                                   src={preview}
//                                   alt="Click to Preview"
//                                   className="preview-image"
//                                 />
//                                 <Nav.Link className="text-danger p-0 fw-600">
//                                   Click to Preview
//                                 </Nav.Link>
//                               </div>
//                             </div>
//                             <Card.Text className="card-text">
//                               Stay ahead with our Business Analytics dashboard,
//                               offering insights into the sentiment of Dubai
//                               businesses for the upcoming quarter. This platform
//                               also delivers the latest trends and sectoral
//                               distribution of Dubai Chambers members, along with
//                               Certificate of Origin data and exporter numbers.
//                               Use these insights to understand the evolving
//                               business dynamics of Dubai and make informed
//                               decisions for your organization's growth.
//                             </Card.Text>
//                             <div className="card-footer-section mt-0">
//                               <Button
//                                 className="btn"
//                                 style={{ fontSize: "12px" }}
//                               >
//                                 Open
//                               </Button>
//                             </div>
//                           </Col>
//                         </Row>
//                       </Card.Body>
//                     </Card>
//                   </Col>
//                   <Row>
//                     <Col lg={12}>
//                       <div className="card-title-danger d-flex align-items-center mb-4 mt-3">
//                         <h3 className="outer-card-title">Research Data</h3>
//                       </div>
//                     </Col>
//                   </Row>
//                   <Col lg={4} className="d-flex mb-4">
//                     <Form.Check
//                       type="checkbox"
//                       className="checkbox-custom mt-0"
//                     />
//                     <Card className="custom-card inner-card">
//                       <Card.Body className="p-0">
//                         <Row>
//                           <Col lg={12}>
//                             <div className="card-header-section">
//                               <div>
//                                 <Card.Title className="card-title custom-card-title">
//                                   Report 1
//                                 </Card.Title>
//                               </div>
//                             </div>

//                             <div className="card-image d-flex align-items-center justify-content-center">
//                               <Image
//                                 src={reportImage2}
//                                 style={{
//                                   width: "50px",
//                                   height: "50px",
//                                   objectFit: "none !important",
//                                 }}
//                                 alt="Report"
//                                 className="image-hover"
//                               />
//                               <div className="preview-link">
//                                 <Image
//                                   src={preview}
//                                   alt="Click to Preview"
//                                   className="preview-image"
//                                 />
//                                 <Nav.Link className="text-danger p-0 fw-600">
//                                   Click to Preview
//                                 </Nav.Link>
//                               </div>
//                             </div>
//                             <Card.Text className="card-text">
//                               Lorem ipsum dolor sit amet, consectetur adipiscing
//                               elit, sed do eiusmod tempor incididunt ut labore
//                               et dolore magna aliqua. Ut enim ad minim veniam,
//                               quis nostrud exercitation ullamco laboris nisi ut
//                               aliquip ex ea commodo consequat.
//                             </Card.Text>
//                             <div className="card-footer-section mt-0">
//                               <Button
//                                 className="btn"
//                                 style={{ fontSize: "12px" }}
//                               >
//                                 Open
//                               </Button>
//                             </div>
//                           </Col>
//                         </Row>
//                       </Card.Body>
//                     </Card>
//                   </Col>
//                   <Col lg={4} className="d-flex mb-4">
//                     <Form.Check
//                       type="checkbox"
//                       className="checkbox-custom mt-0"
//                     />
//                     <Card className="custom-card inner-card">
//                       <Card.Body className="p-0">
//                         <Row>
//                           <Col lg={12}>
//                             <div className="card-header-section">
//                               <div>
//                                 <Card.Title className="card-title custom-card-title">
//                                   Report 2
//                                 </Card.Title>
//                               </div>
//                             </div>

//                             <div className="card-image d-flex align-items-center justify-content-center">
//                               <Image
//                                 src={reportImage2}
//                                 style={{
//                                   width: "50px",
//                                   height: "50px",
//                                   objectFit: "none !important",
//                                 }}
//                                 alt="Report"
//                                 className="image-hover"
//                               />
//                               <div className="preview-link">
//                                 <Image
//                                   src={preview}
//                                   alt="Click to Preview"
//                                   className="preview-image"
//                                 />
//                                 <Nav.Link className="text-danger p-0 fw-600">
//                                   Click to Preview
//                                 </Nav.Link>
//                               </div>
//                             </div>
//                             <Card.Text className="card-text">
//                               Lorem ipsum dolor sit amet, consectetur adipiscing
//                               elit, sed do eiusmod tempor incididunt ut labore
//                               et dolore magna aliqua. Ut enim ad minim veniam,
//                               quis nostrud exercitation ullamco laboris nisi ut
//                               aliquip ex ea commodo consequat.
//                             </Card.Text>
//                             <div className="card-footer-section mt-0">
//                               <Button
//                                 className="btn"
//                                 style={{ fontSize: "12px" }}
//                               >
//                                 Open
//                               </Button>
//                             </div>
//                           </Col>
//                         </Row>
//                       </Card.Body>
//                     </Card>
//                   </Col>
//                   <Col lg={4} className="d-flex mb-4">
//                     <Form.Check
//                       type="checkbox"
//                       className="checkbox-custom mt-0"
//                     />
//                     <Card className="custom-card inner-card">
//                       <Card.Body className="p-0">
//                         <Row>
//                           <Col lg={12}>
//                             <div className="card-header-section">
//                               <div>
//                                 <Card.Title className="card-title custom-card-title">
//                                   Report 3
//                                 </Card.Title>
//                               </div>
//                             </div>

//                             <div className="card-image d-flex align-items-center justify-content-center">
//                               <Image
//                                 src={reportImage2}
//                                 style={{
//                                   width: "50px",
//                                   height: "50px",
//                                   objectFit: "none !important",
//                                 }}
//                                 alt="Report"
//                                 className="image-hover"
//                               />
//                               <div className="preview-link">
//                                 <Image
//                                   src={preview}
//                                   alt="Click to Preview"
//                                   className="preview-image"
//                                 />
//                                 <Nav.Link className="text-danger p-0 fw-600">
//                                   Click to Preview
//                                 </Nav.Link>
//                               </div>
//                             </div>
//                             <Card.Text className="card-text">
//                               Lorem ipsum dolor sit amet, consectetur adipiscing
//                               elit, sed do eiusmod tempor incididunt ut labore
//                               et dolore magna aliqua. Ut enim ad minim veniam,
//                               quis nostrud exercitation ullamco laboris nisi ut
//                               aliquip ex ea commodo consequat.
//                             </Card.Text>
//                             <div className="card-footer-section mt-0">
//                               <Button
//                                 className="btn"
//                                 style={{ fontSize: "12px" }}
//                               >
//                                 Open
//                               </Button>
//                             </div>
//                           </Col>
//                         </Row>
//                       </Card.Body>
//                     </Card>
//                   </Col>
//                 </Row>
//               </Card>
//             </Col>
//           </Row>
//         </div>
//       </div>
//     </>
//   );
// };

// export default MySubscription;

import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import CustomCardForSunscribedReport from "../components/ui/CustomCardForSunscribedReport";
import reportImage from "../assets/dc-report.png";
import reportImage2 from "../assets/pdf.png";
import preview from "../assets/icons/view.png";
import "../dashboard.css";
import SubscriptionService from "../services/subscriptionService";

const MySubscription = () => {
  const [cardData, setCardData] = useState([
    {
      title: "Trade Data Monitor Report (TDM)",
      imageSrc: reportImage,
      previewSrc: preview,
      description:
        "Explore the latest trade trends of the UAE in 20 strategic food products. Our dashboard provides valuable insights into trade growth, highlights key monthly trading partners, and showcases the top global importers and exporters.",
      buttonLink: "/ViewReport",
    },
    {
      title: "Country Fact Sheet",
      imageSrc: reportImage,
      previewSrc: preview,
      description:
        "Our Country Fact Sheet dashboard offers key economic and business indicators, providing insights into a country's competitiveness, market size, tax environment, and other business opportunities.",
    },
    {
      title: "Business Analytics",
      imageSrc: reportImage,
      previewSrc: preview,
      description:
        "Stay ahead with our Business Analytics dashboard, offering insights into the sentiment of Dubai businesses for the upcoming quarter. This platform also delivers the latest trends and sectoral distribution of Dubai Chambers members.",
    },
    {
      title: "Report 1",
      imageSrc: reportImage2,
      previewSrc: preview,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      title: "Report 2",
      imageSrc: reportImage2,
      previewSrc: preview,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      title: "Report 3",
      imageSrc: reportImage2,
      previewSrc: preview,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ]);

  useEffect(() => {
    fetchData();
  }, []); // The empty dependency array ensures the effect runs only once on mount

  const fetchData = async () => {
    try {
      const res = await SubscriptionService.listSubscriptions();
      if (res.statusCode === 200) {
        const mappedCardData = res.data.map((item) => ({
          id: item.id,
          title: `Report: ${item.researchDataId}`, // Adjust as per your requirement
          imageSrc: reportImage, // You can choose different images based on some condition
          previewSrc: preview, // Replace if there's a different preview image logic
          description: `Research Topic: ${
            item.researchDataId
          }. Expiration: ${new Date(
            item.expirationDate
          ).toLocaleDateString()}. Created by: ${item.createdBy}`, // Custom description based on your API data
          buttonLink: `/ViewReport/${item.id}`, // Dynamic link to the report
        }));
        console.log("mappedCardData", mappedCardData);
        setCardData(mappedCardData); // Update the card data with API response
      }
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    }
  };

  return (
    <div className="content page-wrapper">
      <div className="page-content">
        <Row>
          <Col lg={12}>
            <Card className="outer-card">
              <Row>
                <Col lg={12}>
                  <div className="card-title-danger d-flex align-items-center mb-4">
                    <h3 className="outer-card-title">Business Report</h3>
                    <span>
                      Your Subscription plan will be valid for{" "}
                      <strong className="fw-700">3 months.</strong>
                    </span>
                  </div>
                </Col>
              </Row>

              <Row>
                {cardData.map((card, index) => (
                  <CustomCardForSunscribedReport key={index} {...card} />
                ))}
              </Row>

              <Row>
                <Col lg={12}>
                  <div className="card-title-danger d-flex align-items-center mb-4 mt-3">
                    <h3 className="outer-card-title">Research Data</h3>
                  </div>
                </Col>
              </Row>

              {/* <Row>
                {cardData.slice(3).map((card, index) => (
                  <CustomCardForSunscribedReport key={index} {...card} />
                ))}
              </Row> */}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MySubscription;
