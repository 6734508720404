import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Modal,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../assets/logo.png";
import checkMailboxIcon from "../assets/icons/Check-mailbox-icon.png";
import "../login.css";
import "../main.css";
import CRMService from "../services/crmService";
import AuthService from "../services/authService";
import roles from "../request/role";

const Register = () => {
  const [verificationStage, setVerificationStage] = useState(1);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [showMailboxPopup, setShowMailboxPopup] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    company: "",
    memberNumber: "",
    password: "",
    confirmPassword: "",
    role: roles.MEMBER,
  });
  const [checkboxChecked, setCheckboxChecked] = useState(true);

  const validateForm = () => {
    const newErrors = {};
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      company,
      memberNumber,
      password,
      confirmPassword,
    } = formData;

    // Validation logic
    if (!firstName) newErrors.firstName = "First Name is required";
    if (!lastName) newErrors.lastName = "Last Name is required";
    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required";
    } else if (!/^\d{10}$/.test(phoneNumber)) {
      newErrors.phoneNumber = "Phone Number is invalid";
    }
    if (!email) {
      newErrors.email = "Email or Username is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!company) newErrors.company = "Company is required";
    if (!memberNumber) newErrors.memberNumber = "Member Number is required";
    if (!password) {
      newErrors.password = "Password is required";
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }
    if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    return newErrors;
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;
    let newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);
  //  console.log("newOtp", newOtp);
    // Move to next input if available
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleVerifyClick = (e) => {
    e.preventDefault();
    setShowOtpPopup(true); // Open OTP popup
  };

  const handleOtpVerify = () => {
    setShowOtpPopup(false); // Close OTP popup
    setShowMailboxPopup(true); // Open registered mailbox popup
  };

  const handleCloseMailboxPopup = () => {
    setShowMailboxPopup(false); // Close registered mailbox popup
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  //  console.log(name, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Formdata", formData);
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      console.log("newErrors", newErrors);
      setErrors(newErrors);
    } else {
      const requestBody = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        email: formData.email,
        role: formData.role,
        company: formData.company,
        memberNumber: formData.memberNumber,
        password: formData.password,
        createdBy: formData.role,
        status: "ACTIVE",
      };
      try {
        const apiResponse = await AuthService.register(requestBody);
        console.log("apiResponse",apiResponse.data);

        alert("Submitted!", "Email:",apiResponse.data.email, "Password:",apiResponse.data.password);
        // window.location.href = "/login";
        setErrors({});
      } catch (error) {
        console.log(error.response.data.data.errorMessage)
        alert(error.response.data.data.errorMessage)
      }
    }
  };

  const handleCRMVerify = async (e) => {
    e.preventDefault();
    const requestBody = {
      company: formData.company,
      memberNumber: formData.memberNumber,
    };
    alert("crm request body", requestBody);
    setVerificationStage(2);
    // after api
    // const response = CRMService.verifyUser(requestBody);
    // console.log("CRMService.verifyUser Responded with : ",response)
    // if(response.verify===true){
    //   setVerificationStage(2);
    // }
  };

  const handleCheckboxChange = (event) => {
    console.log("event.target.checked", event.target.checked);
    setCheckboxChecked(event.target.checked);
  };

  return (
    <div id="main-wrapper" className="login authentication-page">
      <Container fluid className="px-0">
        <Row className="g-0 min-vh-100">
          <Col md={6} lg={5} className="d-flex flex-column align-items-center">
            <div className="form-wrap">
              <Container className="pt-4">
                <Row className="g-0">
                  <Col xs={11} md={10} lg={9} className="mx-auto">
                    <div className="logo">
                      <a
                        className="fw-600 text-6 text-dark link-underline link-underline-opacity-0"
                        href="index.html"
                        title="Dubai Chambers"
                      >
                        <Image src={logo} alt="Logo" />
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container>

              <Container className="my-auto py-5 container-pl">
                <Row className="g-0">
                  <Col xs={11} md={12} lg={12} className="mx-auto">
                    <div className="card p-25 mb-3">
                      <h1 className="text-12 mb-4">Registration</h1>
                      <Form onSubmit={handleSubmit}>
                        <div className="form-inner position-relative p-3 pt-4 mb-4">
                          <Row>
                            <Col lg={12}>
                              <h5 className="text-12 mb-4 overlay-title">
                                Business details
                              </h5>
                            </Col>
                            <Col md={6} lg={6}>
                              <Form.Group controlId="company">
                                <div className="mb-3 icon-group icon-group-end">
                                  <Form.Label className="fw-500">
                                    Company Name
                                  </Form.Label>
                                  <Form.Select
                                    name="company"
                                    className="bg-white"
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.company}
                                  >
                                    <option value="">Select</option>
                                    <option value="1">Mainland</option>
                                    <option value="2">Freezone</option>
                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid">
                                    {errors.company}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={6} lg={6}>
                              <Form.Group controlId="memberNumber">
                                <Form.Label className="fw-500">
                                  Member Number / License Number
                                </Form.Label>
                                <div className="mb-0 icon-group icon-group-end">
                                  <Form.Control
                                    type="text"
                                    name="memberNumber"
                                    className="form-control-lg bg-white"
                                    placeholder="17847"
                                    required
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.memberNumber}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.memberNumber}
                                  </Form.Control.Feedback>
                                </div>
                                <p className="text-2 text-end">
                                  <a
                                    onClick={handleCRMVerify}
                                    className="text-warning"
                                  >
                                    Verify*
                                  </a>
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                        <div className="form-inner position-relative p-3 pt-4 mb-4">
                          <Row>
                            <Col lg={12}>
                              <h5 className="text-12 mb-4 overlay-title">
                                Personal Details
                              </h5>
                            </Col>

                            <Col md={6} lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="firstName"
                              >
                                <Form.Label className="fw-500">
                                  First Name
                                </Form.Label>
                                <div className="mb-0 icon-group icon-group-end">
                                  <Form.Control
                                    type="text"
                                    name="firstName"
                                    className="form-control-lg bg-white"
                                    placeholder="Kiran"
                                    required
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.firstName}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.firstName}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={6} lg={6}>
                              <Form.Group className="mb-3" controlId="lastName">
                                <Form.Label className="fw-500">
                                  Last Name
                                </Form.Label>
                                <div className="mb-0 icon-group icon-group-end">
                                  <Form.Control
                                    type="text"
                                    name="lastName"
                                    className="form-control-lg bg-white"
                                    placeholder="bokde"
                                    required
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.lastName}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.lastName}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={6} lg={6}>
                              <Form.Group className="mb-3" controlId="email">
                                <Form.Label className="fw-500">
                                  Email Address
                                </Form.Label>
                                <div className="mb-0 icon-group icon-group-end">
                                  <Form.Control
                                    type="email"
                                    name="email"
                                    className="form-control-lg bg-white"
                                    placeholder="Kin@gmail.com"
                                    required
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.email}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={6} lg={6}>
                              <Form.Group controlId="phoneNumber">
                                <Form.Label className="fw-500">
                                  Mobile Number
                                </Form.Label>
                                <div className="mb-0 icon-group icon-group-end">
                                  <Form.Control
                                    type="text"
                                    name="phoneNumber"
                                    className="form-control-lg bg-white"
                                    placeholder="9999999999"
                                    required
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.phoneNumber}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.phoneNumber}
                                  </Form.Control.Feedback>
                                </div>
                                <p className="text-2 text-end">
                                  <a
                                    onClick={handleVerifyClick}
                                    className="text-warning"
                                  >
                                    Verify*
                                  </a>
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>
                          <div>
                            {/* OTP Modal */}
                            <Modal
                              centered
                              show={showOtpPopup}
                              onHide={() => setShowOtpPopup(false)}
                            >
                              <Modal.Header className="border-0 m-auto pb-0">
                                <Modal.Title>
                                  <h1 className="text-12 mb-0 text-center">
                                    OTP
                                  </h1>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body style={{ padding: "35px" }}>
                                <Form.Group controlId="formOtp">
                                  <h5 className="text-2 text-center">
                                    Complete the registration by entering the
                                    OTP <br />
                                    we sent to mobile no. 4352544132
                                  </h5>
                                  <div className="otp-container">
                                    {otp.map((data, index) => {
                                      return (
                                        <input
                                          className="otp-input"
                                          type="text"
                                          maxLength="1"
                                          key={index}
                                          value={data}
                                          onChange={(e) =>
                                            handleChange(e.target, index)
                                          }
                                          onFocus={(e) => e.target.select()}
                                        />
                                      );
                                    })}
                                  </div>
                                  <div className="d-grid">
                                    <Button
                                      className="btn-secondary btn btn-lg"
                                      onClick={handleOtpVerify}
                                    >
                                      Verify
                                    </Button>
                                  </div>
                                </Form.Group>
                              </Modal.Body>
                            </Modal>

                            {/* Mailbox Modal */}
                            <Modal
                              centered
                              show={showMailboxPopup}
                              onHide={handleCloseMailboxPopup}
                            >
                              <Modal.Header className="border-0 m-auto pb-0">
                                <Modal.Title>
                                  <h1 className="text-12 mb-0 text-center">
                                    Check registered mailbox
                                  </h1>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body style={{ padding: "35px" }}>
                                <div className="text-center mb-3">
                                  <Image
                                    src={checkMailboxIcon}
                                    height={54}
                                    alt="Check mailbox icon"
                                  />
                                </div>
                                <h5 className="text-2 text-center mb-3">
                                  Please verify your mail id from the link sent
                                  on the registered email address
                                  Shantanu@gmail.com
                                </h5>
                                <div className="note-area">
                                  <p className="text-secondary">
                                    <span className="text-danger fw-500">
                                      Note:
                                    </span>{" "}
                                    Please be patient with the time taken to
                                    receive the email as it can vary. If you
                                    can’t find the email from Dubai Chambers in
                                    your inbox, please check your “Junk/Spam”
                                    folder.
                                  </p>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                        </div>
                        <div className="form-inner position-relative p-3 pt-4">
                          <Row>
                            <Col lg={12}>
                              <h5 className="text-12 mb-4 overlay-title">
                                Set Password
                              </h5>
                            </Col>

                            <Col md={6} lg={6}>
                              <Form.Group className="mb-3" controlId="password">
                                <Form.Label className="fw-500">
                                  New password
                                </Form.Label>
                                <div className="mb-0 icon-group icon-group-end">
                                  <Form.Control
                                    type="password"
                                    name="password"
                                    className="form-control-lg bg-white"
                                    placeholder="Pasw*123"
                                    required
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.password}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={6} lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="confirmPassword"
                              >
                                <Form.Label className="fw-500">
                                  Confirm password
                                </Form.Label>
                                <div className="mb-0 icon-group icon-group-end">
                                  <Form.Control
                                    type="password"
                                    name="confirmPassword"
                                    className="form-control-lg bg-white"
                                    placeholder="Pasw*123"
                                    required
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.confirmPassword}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.confirmPassword}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <ul className="ms-3">
                                <li>Minimum 7 characters</li>
                                <li>
                                  At least 1 uppercase (A-Z) At least 1
                                  lowercase (a-z)
                                </li>
                                <li>At least 1 number (0-9)</li>
                                <li>
                                  At least 1 special character as mentioned
                                  below : @ ! & $ ? % * (use any out of 7)
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </div>
                        <Form.Group controlId="formBasicCheckbox">
                          <Form.Check
                            required
                            type="checkbox"
                            className="text-light-grey mt-3"
                            label={
                              <>
                                I accept the terms
                                <a
                                  href="/privacy-policy"
                                  className="text-light-blue"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {" "}
                                  Privacy Policy{" "}
                                </a>
                                and
                                <a
                                  href="/terms-and-conditions"
                                  className="text-dark-blue"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {" "}
                                  T&Cs!{" "}
                                </a>
                              </>
                            }
                            onChange={handleCheckboxChange}
                            feedback="You must accept the terms and conditions."
                          />
                          {checkboxChecked && (
                            <Form.Control.Feedback type="invalid">
                              You must accept the terms and conditions.
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>

                        <Col lg={12}>
                          <div className="d-grid my-4 mb-2">
                            <Button
                              type="submit"
                              className="w-50 btn-secondary btn btn-lg"
                              onClick={handleSubmit}
                            >
                              Sign up
                            </Button>
                          </div>
                          <p className="text-2 text-muted">
                            You already have an account?{" "}
                            <a href="/login" className="text-danger">
                              Log in
                            </a>
                          </p>
                        </Col>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
          <Col md={6} lg={7} className="bg-light">
            <div className="hero-wrap d-flex align-items-center h-100">
              <div className="login-image-container hero-bg hero-bg-scroll">
                <div className="fixed-btn">
                  <Button className="btn btn-red" href="/Contact">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                        stroke="white"
                        stroke-width="1.5"
                      />
                      <path
                        d="M10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9C14 9.39815 13.8837 9.76913 13.6831 10.0808C13.0854 11.0097 12 11.8954 12 13V13.5"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M11.9919 17H12.0009"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>{" "}
                    Help
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Register;
