import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/logo.png';
import '../login.css';
import '../main.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import {validateResetPasswordForm as ValidateResetPasswordForm} from '../request/validationFunctions';
import AuthService from "../services/authService";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [emailId, setEmailId] = useState(useParams('emailId'));


  // Toggle password visibility for the new password
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Toggle password visibility for the confirm password
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit =async (e) => {
    e.preventDefault();
    const newErrors = ValidateResetPasswordForm(newPassword,confirmPassword)
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try{
        console.log("reset password request",emailId,newPassword)
        const apiResponse=await AuthService.forgotPassword(emailId,newPassword)
        console.log("reset password response",apiResponse)
      }catch(error){
        
      }
      setErrors({});
      setFormSubmitted(true);
      console.log('Reset password form submitted', { newPassword, confirmPassword });
    }
  };

  return (
    <div id="main-wrapper" className="login authentication-page">
      <Container fluid className="px-0">
        <Row className="g-0 min-vh-100">
          <Col md={6} lg={5} className="d-flex flex-column align-items-center">
            <Container className="pt-4">
              <Row className="g-0">
                <Col xs={11} md={10} lg={9} className="mx-auto">
                  <div className="logo">
                    <a
                      className="fw-600 text-6 text-dark link-underline link-underline-opacity-0"
                      href="index.html"
                      title="Dubai Chambers"
                    >
                      <Image src={logo} alt="Logo" />
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>

            <Container className="my-auto py-5">
              <Row className="g-0">
                <Col xs={11} md={10} lg={9} className="mx-auto">
                  <div className="form-inner">
                    <h1 className="text-12 mb-4 text-center">Reset password</h1>
                    <Form id="resetPasswordForm" onSubmit={handleSubmit}>
                      <Form.Group controlId="newPassword">
                        <Form.Label className="fw-500">New password</Form.Label>
                        <div className="mb-3 icon-group icon-group-end">
                          <Form.Control
                            type={showPassword ? 'text' : 'password'}
                            className="bg-white"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            isInvalid={!!errors.newPassword}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.newPassword}
                          </Form.Control.Feedback>
                          <span
                            className="icon-inside text-muted"
                            onClick={togglePasswordVisibility}
                            style={{ cursor: 'pointer' }}
                          >
                            <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                          </span>
                        </div>
                      </Form.Group>

                      <Form.Group controlId="confirmPassword">
                        <Form.Label className="fw-500">Confirm password</Form.Label>
                        <div className="mb-0 icon-group icon-group-end">
                          <Form.Control
                            type={showConfirmPassword ? 'text' : 'password'}
                            className="form-control-lg bg-white"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            isInvalid={!!errors.confirmPassword}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.confirmPassword}
                          </Form.Control.Feedback>
                          <span
                            className="icon-inside text-muted"
                            onClick={toggleConfirmPasswordVisibility}
                            style={{ cursor: 'pointer' }}
                          >
                            <i className={`fas ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                          </span>
                        </div>
                      </Form.Group>

                      <div className="d-grid mt-5 mb-2">
                        <Button type="submit" onClick={handleSubmit} className="btn-secondary btn btn-lg">
                          Submit
                        </Button>
                      </div>
                    </Form>
                    {formSubmitted && (
                      <p className="text-center text-success mt-3">
                        Your password has been reset successfully.
                      </p>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md={6} lg={7} className="bg-light">
            <div className="hero-wrap d-flex align-items-center h-100">
              <div className="login-image-container hero-bg hero-bg-scroll"></div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResetPassword;
