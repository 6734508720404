import React from 'react';
import "../dashboard.css";
import { Row, Dropdown, Form, Image, Pagination, Breadcrumb } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import reportImage from '../assets/report.png';
import report3 from '../assets/dc-report.png';
import reportImage2 from '../assets/pdf.png';


const ViewReport = () => {
    return (
        <>
            <div className="content page-wrapper">
                <div className='page-content'>

                    <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <Breadcrumb className='mb-0'>
                            <Breadcrumb.Item >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#D7282F" stroke-width="1.5" />
                                    <path d="M13.5 16C13.5 16 10.5 13.054 10.5 12C10.5 10.9459 13.5 8 13.5 8" stroke="#D7282F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg> Home</Breadcrumb.Item>
                            <Breadcrumb.Item className='fw-700'><strong>business Groups</strong></Breadcrumb.Item>
                        </Breadcrumb>
                        <div class="ms-auto">
                            <div class="btn-group">
                                <button type="button" class="btn btn-primary">Download</button>

                            </div>
                        </div>
                    </div>


                    <Row>
                        <Col lg={12}>
                            <Image src={report3} className='w-100 mb-5' alt='' />
                        </Col>
                        <Col lg={12}>
                            <div className='card-title-danger d-flex align-items-center mb-3'>
                                <h3 className='outer-card-title'>
                                    Share Feedback
                                </h3>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <Card className='outer-card'>
                                <Row>
                                    <Col lg={12}>



                                        <Form>

                                            <Form.Group controlId="formTextarea">
                                                <Form.Label className="fw-500" style={{ fontSize: '14px' }}>Feedback</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    placeholder=""
                                                />
                                            </Form.Group>


                                            <div className="my-4 mb-2">

                                                <Button
                                                    type="submit"
                                                    className="btn"
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </Form>

                                    </Col>
                                </Row>

                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default ViewReport;
