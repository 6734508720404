import React, { useEffect, useState } from "react";
import "../dashboard.css";
import {
  Row,
  Form,
  Image,
  Pagination,
  Badge,
  Button,
  Modal,
  Nav,
} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import reportImage from "../assets/dc-report.png";
import reportImage2 from "../assets/pdf.png";
import CustomCardWithProduct from "../components/ui/CustomCardWithProduct";
import CustomCard from "../components/ui/CustomCard";
import CustomCardForData from "../components/ui/CustomCardForData";
import preview from "../assets/icons/view.png";

import productService from "../services/productService";
import reportService from "../services/reportService";
import subscriptionService from "../services/subscriptionService";

const Dashboard = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [responseData, setResponseData] = useState([""]);
  const [dataResponse, setDataResponse] = useState([""]);

  const [checkedCards, setCheckedCards] = useState(new Set());

  const products = [
    "Apple",
    "Banana",
    "Pineapple",
    "Onion",
    "Tomato",
    "Pulses",
    "Rice",
    "Oils",
    "Goat meat",
  ];
  // const responseData = [
  //   {
  //     title: "Trade Data Monitor Report (TDM)",
  //     description:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...",
  //     imageSrc: reportImage,
  //     altText: "Report",
  //     onPreview: preview,
  //     checkbox: true,
  //   },
  //   {
  //     title: "Country Fact Sheet Report",
  //     description:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...",
  //     imageSrc: reportImage,
  //     altText: "Report",
  //     onPreview: "console.log('Preview clicked')",
  //     checkbox: true,
  //   },
  //   {
  //     title: "Business Dynamics Report",
  //     description:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...",
  //     imageSrc: reportImage,
  //     altText: "Report",
  //     onPreview: "console.log('Preview clicked')",
  //     checkbox: true,
  //   },
  //   {
  //     title: "Business Dynamics Report",
  //     description:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...",
  //     imageSrc: reportImage,
  //     altText: "Report",
  //     onPreview: "console.log('Preview clicked')",
  //     checkbox: true,
  //   },
  //   {
  //     title: "Business Dynamics Report",
  //     description:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...",
  //     imageSrc: reportImage,
  //     altText: "Report",
  //     onPreview: "console.log('Preview clicked')",
  //     checkbox: true,
  //   },
  //   {
  //     title: "Business Dynamics Report",
  //     description:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...",
  //     imageSrc: reportImage,
  //     altText: "Report",
  //     onPreview: "console.log('Preview clicked')",
  //     checkbox: true,
  //   },
  //   {
  //     title: "Business Dynamics Report",
  //     description:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...",
  //     imageSrc: reportImage,
  //     altText: "Report",
  //     onPreview: "console.log('Preview clicked')",
  //     checkbox: true,
  //   },
  // ];
  // const dataResponse = [
  //   {
  //     id: 1,
  //     title: "Country Fact Sheet Report",
  //     description:
  //       "An in-depth overview of country-specific data including GDP, population, and more.",
  //     imageSrc: reportImage2,
  //     altText: "Country Fact Sheet Report",
  //   },
  //   {
  //     id: 2,
  //     title: "Trade Data Monitor Report (TDM)",
  //     description:
  //       "This report monitors and analyzes international trade flows and trends.",
  //     imageSrc: reportImage2,
  //     altText: "Trade Data Monitor Report",
  //   },
  //   {
  //     id: 3,
  //     title: "Energy Consumption Report",
  //     description:
  //       "A comprehensive breakdown of energy usage across various sectors.",
  //     imageSrc: reportImage2,
  //     altText: "Energy Consumption Report",
  //   },
  //   {
  //     id: 4,
  //     title: "Financial Markets Overview",
  //     description:
  //       "An analysis of stock market trends and financial performance indicators.",
  //     imageSrc: reportImage2,
  //     altText: "Financial Markets Overview",
  //   },
  //   {
  //     id: 5,
  //     title: "Climate Change Impact Assessment",
  //     description:
  //       "Evaluating the economic and environmental effects of climate change globally.",
  //     imageSrc: reportImage2,
  //     altText: "Climate Change Impact",
  //   },
  //   {
  //     id: 6,
  //     title: "Technology Adoption Report",
  //     description:
  //       "Insights into the latest technology trends and adoption rates in various industries.",
  //     imageSrc: reportImage2,
  //     altText: "Technology Adoption Report",
  //   },
  //   {
  //     id: 7,
  //     title: "Healthcare Trends Analysis",
  //     description:
  //       "A detailed report on emerging healthcare trends and advancements in medical technology.",
  //     imageSrc: reportImage2,
  //     altText: "Healthcare Trends Analysis",
  //   },
  //   {
  //     id: 8,
  //     title: "Retail Sector Performance",
  //     description:
  //       "An analysis of the retail industry's performance, focusing on consumer behavior and market trends.",
  //     imageSrc: reportImage2,
  //     altText: "Retail Sector Performance",
  //   },
  // ];

  //initially call
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let reportList = await reportService.listReports();
      setResponseData(reportList.data);
      console.log("reportList", reportList.data);
      let productList = await productService.listProducts();
      console.log("productList", productList.data);
      setDataResponse(productList.data);
    } catch (error) {}
  };

  console.log("aaaaaaa", responseData, dataResponse);
  const handleCheckboxChange = (product) => {
    if (selectedProducts.includes(product)) {
      setSelectedProducts(selectedProducts.filter((item) => item !== product));
    } else {
      setSelectedProducts([...selectedProducts, product]);
    }
  };

  const handleCheckboxSelect = (cardId, isChecked) => {
    console.log(`Card ID: ${cardId}, Checked: ${isChecked}`);
    setCheckedCards((prevCheckedCards) => {
      const newCheckedCards = new Set(prevCheckedCards);
      if (isChecked) {
        newCheckedCards.add(cardId);
      } else {
        newCheckedCards.delete(cardId);
      }
      return newCheckedCards;
    });
  };

  useEffect(() => {
    console.log("Checked Cards:", Array.from(checkedCards));
  }, [checkedCards]);

  const selectProduct = () => {
    setShowModal(true);
  };
  const previewImage = () => {
    console.log("preview clicked");
  };

  const handleSave = () => {
    // Handle save logic here
    console.log("Selected Products:", selectedProducts);
    setShowModal(false);
  };

  // States for Tab 1 (Business Report)
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const cardsPerPage = 3;
  console.log("responseData.length ", responseData.length);
  // Filter the data for Tab 1 based on search query
  const filteredCards =
    responseData.length > 0
      ? responseData.filter((card) =>
          card?.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
        )
      : [];
  // Calculate total pages and data slice for Tab 1
  const totalPages = Math.ceil(filteredCards?.length / cardsPerPage);
  const startIndex = (currentPage - 1) * cardsPerPage;
  const currentCards = filteredCards?.slice(
    startIndex,
    startIndex + cardsPerPage
  );

  // Handle pagination for Tab 1
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle search change for Tab 1
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on search change
  };

  // States for Tab 2 (Research Data)
  const [currentPage1, setCurrentPage1] = useState(1);
  const [searchQuery1, setSearchQuery1] = useState("");
  const cardsPerPage1 = 3;

  // Filter the data for Tab 2 based on search query
  const filteredCards1 =
    dataResponse.length > 0
      ? dataResponse?.filter((card) =>
          card?.title?.toLowerCase()?.includes(searchQuery1.toLowerCase())
        )
      : [];

  // Calculate total pages and data slice for Tab 2
  const totalPages1 = Math.ceil(filteredCards1?.length / cardsPerPage1);
  const startIndex1 = (currentPage1 - 1) * cardsPerPage1;
  const currentCards1 = filteredCards1?.slice(
    startIndex1,
    startIndex1 + cardsPerPage1
  );

  // Handle pagination for Tab 2
  const handlePageChange1 = (pageNumber) => {
    setCurrentPage1(pageNumber);
  };

  // Handle search change for Tab 2
  const handleSearchChange1 = (e) => {
    setSearchQuery1(e.target.value);
    setCurrentPage1(1); // Reset to first page on search change
  };

  return (
    <>
      <div className="content page-wrapper">
        <div className="page-content">
          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Tabs
                  defaultActiveKey="home"
                  id="uncontrolled-tab-example"
                  className="mb-4"
                >
                  <Tab eventKey="home" title="Business Report">
                    <Row>
                      {currentCards.length != 0 ? (
                        <>
                          {currentCards.map((card, index) =>
                            card.productIds.length > 0 ? (
                              <CustomCardWithProduct
                                key={index}
                                title={card.title}
                                description={card.description}
                                imageSrc={card.imageSrc}
                                altText={card.altText}
                                onSelectProduct={selectProduct}
                                onPreview={previewImage}
                                checkbox={{
                                  checked: checkedCards.has(card.id),
                                  onChange: (e) =>
                                    handleCheckboxSelect(
                                      card.title,
                                      e.target.checked
                                    ),
                                }}
                              />
                            ) : (
                              <CustomCard
                                key={index}
                                title={card.title}
                                description={card.description}
                                imageSrc={card.imageSrc}
                                altText={card.altText}
                                onPreview={previewImage}
                                checkbox={{
                                  checked: checkedCards.has(card.id),
                                  onChange: (e) =>
                                    handleCheckboxSelect(
                                      card.title,
                                      e.target.checked
                                    ),
                                }}
                              />
                            )
                          )}
                        </>
                      ) : (
                        <div style={{ justifyContent: "center" }}>
                          No data found
                        </div>
                      )}
                    </Row>
                    <Row className="align-items-center">
                      <Col lg={3}>
                        <Button variant="btn" className="btn-light ms-4">
                          Save
                        </Button>
                      </Col>

                      <Col lg={9}>
                        <Pagination style={{ justifyContent: "end" }}>
                          <Pagination.First
                            onClick={() => handlePageChange(1)}
                            disabled={currentPage === 1}
                          />
                          <Pagination.Prev
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                          />

                          {/* Generate page numbers dynamically */}
                          {[...Array(totalPages)].map((_, pageNumber) => (
                            <Pagination.Item
                              key={pageNumber + 1}
                              active={pageNumber + 1 === currentPage}
                              onClick={() => handlePageChange(pageNumber + 1)}
                            >
                              {pageNumber + 1}
                            </Pagination.Item>
                          ))}

                          <Pagination.Next
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                          />
                          <Pagination.Last
                            onClick={() => handlePageChange(totalPages)}
                            disabled={currentPage === totalPages}
                          />
                        </Pagination>
                      </Col>
                    </Row>
                  </Tab>

                  <Tab eventKey="profile" title="Research Data">
                    <Row>
                      {currentCards1.length != 0 ? (
                        <>
                          {currentCards1.map((report) => (
                            <CustomCardForData
                              key={report.id}
                              title={report.title}
                              description={report.description}
                              imageSrc={reportImage2}
                              altText={report.altText}
                              onPreview={() => console.log("Preview clicked")}
                              checkbox={true}
                              imageStyle={{ objectFit: "none" }}
                            />
                          ))}
                        </>
                      ) : (
                        <div style={{ justifyContent: "center" }}>
                          No data found
                        </div>
                      )}
                    </Row>
                    <Row className="align-items-center">
                      <Col lg={3}>
                        <Button variant="btn" className="btn-light ms-4">
                          Save
                        </Button>
                      </Col>

                      <Col lg={9}>
                        <Pagination style={{ justifyContent: "end" }}>
                          <Pagination.First
                            onClick={() => handlePageChange1(1)}
                            disabled={currentPage1 === 1}
                          />
                          <Pagination.Prev
                            onClick={() => handlePageChange1(currentPage1 - 1)}
                            disabled={currentPage1 === 1}
                          />

                          {/* Generate page numbers dynamically */}
                          {[...Array(totalPages1)].map((_, index) => (
                            <Pagination.Item
                              key={index + 1}
                              active={index + 1 === currentPage1}
                              onClick={() => handlePageChange1(index + 1)}
                            >
                              {index + 1}
                            </Pagination.Item>
                          ))}

                          <Pagination.Next
                            onClick={() => handlePageChange1(currentPage1 + 1)}
                            disabled={currentPage1 === totalPages1}
                          />
                          <Pagination.Last
                            onClick={() => handlePageChange1(totalPages1)}
                            disabled={currentPage1 === totalPages1}
                          />
                        </Pagination>
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
                <div className="search-tab-control">
                  <Form.Group
                    style={{ padding: "10px 10px", position: "relative" }}
                    controlId="formBasicSearch"
                  >
                    <svg
                      style={{
                        position: "absolute",
                        top: "18px",
                        left: "20px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M14.5835 14.5831L18.3335 18.3331"
                        stroke="#093B60"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.6665 9.16687C16.6665 5.02474 13.3087 1.66687 9.1665 1.66687C5.02437 1.66687 1.6665 5.02474 1.6665 9.16687C1.6665 13.309 5.02437 16.6669 9.1665 16.6669C13.3087 16.6669 16.6665 13.309 16.6665 9.16687Z"
                        stroke="#093B60"
                        stroke-width="1.25"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <Form.Control
                      type="text"
                      className="form-search-field"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={(e) => {
                        handleSearchChange(e);
                        handleSearchChange1(e);
                      }} // Update search query on input change
                    />
                  </Form.Group>
                </div>
              </Card>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col lg={12}>
              <div className="card-title-danger d-flex align-items-center mb-3">
                <h3 className="outer-card-title">Preview</h3>
              </div>
            </Col>
            <Col lg={12}>
              <Card className="outer-card">
                <div className="mb-2">
                  {/* Business Report Section */}
                  <Row>
                    <Col lg={12}>
                      <div className="card-title-success d-flex align-items-center mb-4">
                        <h3 className="outer-card-title">Business Report</h3>
                        <Badge variant={""}>Subscribed</Badge>
                      </div>
                    </Col>
                  </Row>
                  <Row className="preview-row">
                    <Col>
                      <Form.Check
                        type="checkbox"
                        label="Trade Data Monitor Report (TDM)"
                      />
                      <div className="sub-products">
                        <Row className="ms-0">
                          <Col xs={6} md={6}>
                            <Form.Check type="checkbox" label="Apple" />
                            <Form.Check type="checkbox" label="Banana" />
                          </Col>
                          <Col xs={6} md={6}>
                            <Form.Check type="checkbox" label="Pineapple" />

                            <Form.Check type="checkbox" label="Onion" />
                          </Col>
                          <Col xs={6} md={6}>
                            <Form.Check type="checkbox" label="Tomato" />

                            <Form.Check type="checkbox" label="Apple" />
                          </Col>
                          <Col xs={6} md={6}>
                            <Form.Check type="checkbox" label="Banana" />

                            <Form.Check type="checkbox" label="Onion" />
                            <Form.Check type="checkbox" label="Tomato" />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        label="Country Fact Sheet Report"
                      />
                      <Form.Check
                        type="checkbox"
                        label="Business Dynamics Report"
                      />
                      <Form.Check type="checkbox" label="Business Analytics" />
                    </Col>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        label="Business Dynamics Report"
                      />
                      <Form.Check type="checkbox" label="Business Analytics" />
                    </Col>
                    <Col>
                      <Form.Check type="checkbox" label="Report 1" />
                      <Form.Check type="checkbox" label="Report 2" />
                      <Form.Check type="checkbox" label="Report 3" />
                      <Form.Check type="checkbox" label="Report 4" />
                      <Form.Check type="checkbox" label="Report 5" />
                      <Form.Check type="checkbox" label="Report 6" />
                      <Form.Check type="checkbox" label="Report 7" />
                    </Col>
                  </Row>

                  {/* Research Data Section */}
                  <Row className="mt-3">
                    <Col lg={12}>
                      <div className="card-title-success d-flex align-items-center mb-4">
                        <h3 className="outer-card-title">Research Data</h3>
                        <Badge variant={""}>Subscribed</Badge>
                      </div>
                    </Col>
                  </Row>
                  <Row className="preview-row">
                    <Col xs={12} md={3}>
                      <Form.Check type="checkbox" label="Report 1" />
                      <Form.Check type="checkbox" label="Report 2" />
                      <Form.Check type="checkbox" label="Report 3" />
                      <Form.Check type="checkbox" label="Report 4" />
                    </Col>
                    <Col xs={12} md={3}>
                      <Form.Check type="checkbox" label="Report 5" />
                      <Form.Check type="checkbox" label="Report 6" />
                      <Form.Check type="checkbox" label="Report 7" />
                      <Form.Check type="checkbox" label="Report 8" />
                    </Col>
                    <Col xs={12} md={3}>
                      <Form.Check type="checkbox" label="Report 10" />
                      <Form.Check type="checkbox" label="Report 11" />
                      <Form.Check type="checkbox" label="Report 12" />
                      <Form.Check type="checkbox" label="Report 13" />
                    </Col>
                    <Col xs={12} md={3}>
                      <Form.Check type="checkbox" label="Report 14" />
                      <Form.Check type="checkbox" label="Report 15" />
                      <Form.Check type="checkbox" label="Report 16" />
                      <Form.Check type="checkbox" label="Report 17" />
                    </Col>
                  </Row>

                  {/* Subscribe Button */}
                  <Row className="mt-4">
                    <Col>
                      <Button variant="">Subscribe</Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        className="product-modal"
        show={showModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowModal(false)}
      >
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body className="p-0">
          <Form>
            <Form.Group
              style={{ padding: "10px 10px", position: "relative" }}
              controlId="formBasicSearch"
            >
              <svg
                style={{ position: "absolute", top: "18px", left: "20px" }}
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M14.5835 14.5831L18.3335 18.3331"
                  stroke="#093B60"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.6665 9.16687C16.6665 5.02474 13.3087 1.66687 9.1665 1.66687C5.02437 1.66687 1.6665 5.02474 1.6665 9.16687C1.6665 13.309 5.02437 16.6669 9.1665 16.6669C13.3087 16.6669 16.6665 13.309 16.6665 9.16687Z"
                  stroke="#093B60"
                  stroke-width="1.25"
                  stroke-linejoin="round"
                />
              </svg>
              <Form.Control
                type="text"
                className="form-search-field"
                placeholder="Search"
              />
            </Form.Group>
            {products.map((product, index) => (
              <Form.Check
                key={index}
                type="checkbox"
                label={product}
                onChange={() => handleCheckboxChange(product)}
                checked={selectedProducts.includes(product)}
              />
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-start border-0">
          <Button variant="" className="btn-light" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Dashboard;
