import React, { useState } from "react";
import "../dashboard.css";
import { Row, Form, Breadcrumb, CardBody, Image } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { Alert } from 'react-bootstrap';
import deleteIcon from '../assets/icons/delete.png';
import uploadIcon from '../assets/icons/upload.png';
import { Table, Nav } from 'react-bootstrap';
import editIcon from '../assets/icons/edit.png';

const AddProduct = () => {
    const [image, setImage] = useState(null);
    const [error, setError] = useState(null);
    const maxSize = 10 * 1024 * 1024; // 10 MB
    // Function to handle file drop
    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0]; // Get the first file dropped
        handleFile(file);
    };
    // Function to handle file selection via Browse button
    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        handleFile(file);
    };
    // Function to validate and set the image
    const handleFile = (file) => {
        if (file && file.size <= maxSize && file.type.startsWith('image/')) {
            setImage(file);
            setError(null);
        } else {
            setError('File size exceeds 10 MB or invalid file type.');
        }
    };
    // Prevent default behavior for drag events
    const handleDragOver = (event) => {
        event.preventDefault();
    };
    // add product toggle functionality
    const [isExpanded, setIsExpanded] = useState(false);
    const handleAddProductClick = () => {
        setIsExpanded(!isExpanded); // Toggle between expanded and collapsed states
    };
    const handleSaveClick = () => {
        setIsExpanded(false); // Optionally, you can collapse it on Save
    };

    const products = [
        { name: "Product 1", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit", workspaceId: 26180, tenantId: 57400 },
        { name: "Product 2", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit", workspaceId: 26180, tenantId: 57400 },
    ];




    return (
        <>
            <div className="content page-wrapper">
                <div className="page-content">
                    <Row>
                        <Col lg={12}>
                            <Card className="outer-card">
                                <Card.Header className="bg-white border-0 p-0">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <h5 class="mb-0 fw-700">Report Configuration</h5>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body className="px-2">
                                    <Row>
                                        <Col lg={12}>
                                            <Form>
                                                <div className="form-inner position-relative p-3 mt-3 pt-4 shadow-none">
                                                    <Row>
                                                        <Col lg={12}>
                                                            <h5
                                                                style={{
                                                                    fontSize: "14px",
                                                                    background: "#fff",
                                                                    padding: "0 0px",
                                                                }}
                                                                className="text-12 mb-4 overlay-title"
                                                            >
                                                                Report Details
                                                            </h5>
                                                        </Col>
                                                        <Col lg={4} md={6}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label
                                                                    className="fw-500"
                                                                    style={{ fontSize: "14px" }}
                                                                >
                                                                    Report Name
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="reportName"
                                                                    className="custom-field"
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} md={6}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label
                                                                    className="fw-500"
                                                                    style={{ fontSize: "14px" }}
                                                                >
                                                                    Report ID
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="reportID"
                                                                    className="custom-field"
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} md={6}>
                                                            <Form.Group className="mb-2">
                                                                <Form.Label
                                                                    className="fw-500"
                                                                    style={{ fontSize: "14px" }}
                                                                >
                                                                    Workspace ID
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="Workspace ID"
                                                                    className="custom-field"
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} md={6}>
                                                            <Form.Group className="mb-2">
                                                                <Form.Label
                                                                    className="fw-500"
                                                                    style={{ fontSize: "14px" }}
                                                                >
                                                                    Tenant ID
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="Tenant ID"
                                                                    className="custom-field"
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} md={6}>
                                                            <Form.Group className="mb-2">
                                                                <Form.Label
                                                                    className="fw-500"
                                                                    style={{ fontSize: "14px" }}
                                                                >
                                                                    Workspace ID
                                                                </Form.Label>
                                                                <Form.Group controlId="formTextarea">
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        className="custom-field overflow-hidden"
                                                                        rows={5}
                                                                        placeholder=""
                                                                    />
                                                                </Form.Group>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} md={6}>
                                                            <Form.Label
                                                                className="fw-500"
                                                                style={{ fontSize: "14px" }}
                                                            >
                                                                Upload Sample Image
                                                            </Form.Label>
                                                            <Form.Group>
                                                                <div className="position-relative upload-image-wrap">
                                                                    <div
                                                                        className="border rounded p-2 px-5 text-center"
                                                                        style={{ backgroundColor: '#f4f4f4', cursor: 'pointer' }}
                                                                        onDrop={handleDrop}
                                                                        onDragOver={handleDragOver}
                                                                    >
                                                                        {image ? (
                                                                            <div className="preview-image-box">
                                                                                <img src={URL.createObjectURL(image)} alt="preview" style={{ height: '60px' }} className="" />
                                                                                <p className="overflow-description" style={{ marginTop: '10px', fontSize: '12px' }} title={image.name}>{image.name} ({(image.size / 1024 / 1024).toFixed(2)} MB)</p>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="upload-inner">
                                                                                <p className="mb-0" style={{ fontSize: '12px' }}>
                                                                                    <Image style={{ width: '14px' }} src={uploadIcon} />
                                                                                    <div>
                                                                                        Drop your image here or <br />
                                                                                    </div>
                                                                                    <label htmlFor="file-upload" className="btn  mt-3" style={{ fontSize: '14px' }}>Browse</label></p>
                                                                            </div>
                                                                        )}
                                                                        <input
                                                                            id="file-upload"
                                                                            type="file"
                                                                            accept="image/*"
                                                                            style={{ display: 'none' }}
                                                                            onChange={handleFileSelect}
                                                                        />
                                                                    </div>
                                                                    {error &&
                                                                        <Alert className="mt-3">{error}</Alert>
                                                                    }
                                                                    {image && (
                                                                        <Button className="mt-0 delete-preview-image" onClick={() =>
                                                                            setImage(null)}>
                                                                            <Image src={deleteIcon} />
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <Row className="mt-4">
                                                    <Col lg={isExpanded ? 12 : 3} className={`add-product-btn ${isExpanded ? 'styled-btn' : ''}`}>
                                                        <div className="styled-border-btn">
                                                            <Button onClick={handleAddProductClick} className="btn-light" style={{ fontSize: '14px' }}><i class="fa-solid fa-circle-plus"></i> Add Product</Button>
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        lg={isExpanded ? 12 : 6}
                                                        className={`add-product-form ${isExpanded ? '' : 'd-none'}`}
                                                    >
                                                        <div className="form-inner position-relative p-3 mt-4 mb-4 pt-4 shadow-none">
                                                            <Row>
                                                                <Col lg={12}>
                                                                    <h5
                                                                        style={{
                                                                            fontSize: "14px",
                                                                            background: "#fff",
                                                                            padding: "0 0px",
                                                                        }}
                                                                        className="text-12 mb-4 overlay-title"
                                                                    >
                                                                        Product Details
                                                                    </h5>
                                                                </Col>

                                                                <Col lg={4} md={6}>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label
                                                                            className="fw-500"
                                                                            style={{ fontSize: "14px" }}
                                                                        >
                                                                            Product Name
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="productName"
                                                                            className="custom-field"
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={4} md={6}>
                                                                    <Form.Group className="mb-2">
                                                                        <Form.Label
                                                                            className="fw-500"
                                                                            style={{ fontSize: "14px" }}
                                                                        >
                                                                            Product Description
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            as="textarea"
                                                                            className="custom-field overflow-hidden"
                                                                            rows={1}
                                                                            placeholder=""
                                                                        />
                                                                    </Form.Group>

                                                                </Col>
                                                                <Col lg={4}>
                                                                    <Button
                                                                        style={{ fontSize: "14px" }}
                                                                        className="btn save-btn-mt"
                                                                    >
                                                                        Save
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                            <Row>

                                                            </Row>
                                                        </div>
                                                    </Col>
                                                    <Col lg={12} className={`product-table ${isExpanded ? '' : 'd-none'}`}>
                                                        <Table bordered hover responsive className="mt-3">
                                                            <thead className="table light-thead">
                                                                <tr>
                                                                    <th>Product</th>
                                                                    <th>Description</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {products.map((product, index) => (
                                                                    <tr key={index}>
                                                                        <td><Nav.Link className="text-decoration-underline">{product.name}</Nav.Link></td>
                                                                        <td><p className="overflow-description lh-inherit">{product.description}</p></td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center">
                                                                                <Nav.Link variant="link" className="me-3">
                                                                                    <Image style={{ width: '14px' }} src={editIcon} />
                                                                                </Nav.Link>
                                                                                <Nav.Link variant="link" className="text-danger">
                                                                                    <Image style={{ width: '14px' }} src={deleteIcon} />
                                                                                </Nav.Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                    <Col lg={isExpanded ? 12 : 9} className="add-product-footer-btn text-end">
                                                        <Button className="btn-light me-2">Cancel</Button>
                                                        <Button
                                                            style={{ fontSize: "14px" }}
                                                            className="btn"
                                                        >
                                                            Save
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>



        </>
    );
};
export default AddProduct;