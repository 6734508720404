import axios from "axios";
import CryptoJS from "crypto-js";

import { VERIFYUSER_URL } from './apiConfig';

class CRMService {
      verifyUser(requestBody) {
        return axios
          .post(VERIFYUSER_URL, requestBody)
          .then((response) => {
            //console.log("res",response)
            return response.data;
          });
      }

}

export default new CRMService();