// validationFunctions.js

export  function validateLoginForm(email, password) {
  const newErrors = {};
  // Email validation
  if (!email) {
    newErrors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(email)) {
    newErrors.email = "Invalid email format";
  }

  // Password validation
  const passwordRequirements =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
  if (!password) {
    newErrors.password = "Password is required";
  } else if (!passwordRequirements.test(password)) {
    newErrors.password =
      "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character.";
  }

  return newErrors;
}

export function validateRegistrationForm(
  firstName,
  lastName,
  phoneNumber,
  email,
  role,
  company,
  memberNumber,
  password,
  createdBy,
  status
) {
  const newErrors = {};

  if (!firstName) {
    newErrors.firstName = "First name is required";
  }

  if (!lastName) {
    newErrors.lastName = "Last name is required";
  }

  if (!phoneNumber) {
    newErrors.phoneNumber = "Phone number is required";
  }

  if (!email) {
    newErrors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(email)) {
    newErrors.email = "Invalid email format";
  }

  if (!role) {
    newErrors.role = "Role is required";
  }

  if (!company) {
    newErrors.company = "Company is required";
  }

  if (!memberNumber) {
    newErrors.memberNumber = "Member number is required";
  }

  const passwordRequirements =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
  if (!password) {
    newErrors.password = "Password is required";
  } else if (!passwordRequirements.test(password)) {
    newErrors.password =
      "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character.";
  }

  if (!createdBy) {
    newErrors.createdBy = "Created by is required";
  }

  if (!status) {
    newErrors.status = "Status is required";
  }

  return newErrors;
}

export function validateForgotPasswordForm(emailId) {
  const newErrors = {};

  if (!emailId) {
    newErrors.emailId = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(emailId)) {
    newErrors.emailId = "Invalid email format";
  }

  return newErrors;
}

export function validateResetPasswordForm(newPassword,confirmPassword){
    const newErrors = {};
    if (!newPassword) {
      newErrors.newPassword = 'New password is required';
    } else if (newPassword.length < 6) {
      newErrors.newPassword = 'Password must be at least 6 characters long';
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = 'Confirm password is required';
    } else if (confirmPassword !== newPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    return newErrors;
}

export function validateGetUserByIdForm(id) {
  const newErrors = {};

  if (!id) {
    newErrors.id = "User ID is required";
  }

  return newErrors;
}

export function validateListUsersForm(pageSize, pageNo) {
  const newErrors = {};

  if (!pageSize) {
    newErrors.pageSize = "Page size is required";
  }

  if (!pageNo) {
    newErrors.pageNo = "Page number is required";
  }

  return newErrors;
}

export function validateCreateProductForm(
  productName,
  productDescription,
  status,
  createdBy
) {
  const newErrors = {};

  if (!productName) {
    newErrors.productName = "Product name is required";
  }

  if (!productDescription) {
    newErrors.productDescription = "Product description is required";
  }

  if (!status) {
    newErrors.status = "Status is required";
  }

  if (!createdBy) {
    newErrors.createdBy = "Created by is required";
  }

  return newErrors;
}

export function validateUpdateProductForm(
  id,
  productName,
  productDescription,
  status,
  updatedBy
) {
  const newErrors = {};

  if (!id) {
    newErrors.id = "Product ID is required";
  }

  if (!productName) {
    newErrors.productName = "Product name is required";
  }

  if (!productDescription) {
    newErrors.productDescription = "Product description is required";
  }

  if (!status) {
    newErrors.status = "Status is required";
  }

  if (!updatedBy) {
    newErrors.updatedBy = "Updated by is required";
  }

  return newErrors;
}

export function validateCreateFeedbackForm(
  userId,
  reportId,
  researchDataId,
  feedbackText,
  createdBy
) {
  const newErrors = {};

  if (!userId) {
    newErrors.userId = "User ID is required";
  }

  if (!reportId) {
    newErrors.reportId = "Report ID is required";
  }

  if (!researchDataId) {
    newErrors.researchDataId = "Research Data ID is required";
  }

  if (!feedbackText) {
    newErrors.feedbackText = "Feedback text is required";
  }

  if (!createdBy) {
    newErrors.createdBy = "Created by is required";
  }

  return newErrors;
}

export function validateUpdateFeedbackForm(id, feedbackText, modifiedBy) {
  const newErrors = {};

  if (!id) {
    newErrors.id = "Feedback ID is required";
  }

  if (!feedbackText) {
    newErrors.feedbackText = "Feedback text is required";
  }

  if (!modifiedBy) {
    newErrors.modifiedBy = "Modified by is required";
  }

  return newErrors;
}

export function validateCreateSubscriptionForm(
  userId,
  reportId,
  productIds,
  researchDataId,
  expirationDate,
  createdBy,
  modifiedBy
) {
  const newErrors = {};

  if (!userId) {
    newErrors.userId = "User ID is required";
  }

  if (!reportId) {
    newErrors.reportId = "Report ID is required";
  }

  if (!productIds || productIds.length === 0) {
    newErrors.productIds = "At least one product ID is required";
  }

  if (!researchDataId) {
    newErrors.researchDataId = "Research Data ID is required";
  }

  if (!expirationDate) {
    newErrors.expirationDate = "Expiration date is required";
  }

  if (!createdBy) {
    newErrors.createdBy = "Created by is required";
  }

  if (!modifiedBy) {
    newErrors.modifiedBy = "Modified by is required";
  }

  return newErrors;
}

export function validateUpdateSubscriptionForm(
  id,
  userId,
  reportId,
  productIds,
  researchDataId,
  expirationDate,
  createdBy,
  modifiedBy
) {
  const newErrors = {};

  if (!id) {
    newErrors.id = "Subscription ID is required";
  }

  if (!userId) {
    newErrors.userId = "User ID is required";
  }

  if (!reportId) {
    newErrors.reportId = "Report ID is required";
  }

  if (!productIds || productIds.length === 0) {
    newErrors.productIds = "At least one product ID is required";
  }

  if (!researchDataId) {
    newErrors.researchDataId = "Research Data ID is required";
  }

  if (!expirationDate) {
    newErrors.expirationDate = "Expiration date is required";
  }

  if (!createdBy) {
    newErrors.createdBy = "Created by is required";
  }

  if (!modifiedBy) {
    newErrors.modifiedBy = "Modified by is required";
  }

  return newErrors;
}

export function validateCreateReportForm(
  reportID,
  reportName,
  reportDescription,
  sampleImage,
  workspaceID,
  tenantID,
  status,
  createdBy,
  productIds
) {
  const newErrors = {};

  if (!reportID) {
    newErrors.reportID = "Report ID is required";
  }

  if (!reportName) {
    newErrors.reportName = "Report name is required";
  }

  if (!reportDescription) {
    newErrors.reportDescription = "Report description is required";
  }

  if (!sampleImage) {
    newErrors.sampleImage = "Sample image is required";
  } else if (!/^https?:\/\/.*\.(jpg|jpeg|png|gif)$/.test(sampleImage)) {
    newErrors.sampleImage = "Invalid image URL";
  }

  if (!workspaceID) {
    newErrors.workspaceID = "Workspace ID is required";
  }

  if (!tenantID) {
    newErrors.tenantID = "Tenant ID is required";
  }

  if (!status) {
    newErrors.status = "Status is required";
  }

  if (!createdBy) {
    newErrors.createdBy = "Created by is required";
  }

  if (!productIds || productIds.length === 0) {
    newErrors.productIds = "At least one product ID is required";
  }

  return newErrors;
}

export function validateUpdateReportForm(
  id,
  reportID,
  reportName,
  reportDescription,
  sampleImage,
  workspaceID,
  tenantID,
  status,
  createdBy,
  productIds
) {
  const newErrors = {};

  if (!id) {
    newErrors.id = "Report ID is required";
  }

  if (!reportID) {
    newErrors.reportID = "Report ID is required";
  }

  if (!reportName) {
    newErrors.reportName = "Report name is required";
  }

  if (!reportDescription) {
    newErrors.reportDescription = "Report description is required";
  }

  if (!sampleImage) {
    newErrors.sampleImage = "Sample image is required";
  } else if (!/^https?:\/\/.*\.(jpg|jpeg|png|gif)$/.test(sampleImage)) {
    newErrors.sampleImage = "Invalid image URL";
  }

  if (!workspaceID) {
    newErrors.workspaceID = "Workspace ID is required";
  }

  if (!tenantID) {
    newErrors.tenantID = "Tenant ID is required";
  }

  if (!status) {
    newErrors.status = "Status is required";
  }

  if (!createdBy) {
    newErrors.createdBy = "Created by is required";
  }

  if (!productIds || productIds.length === 0) {
    newErrors.productIds = "At least one product ID is required";
  }

  return newErrors;
}

export function validateCreateResearchDataForm(
  blobLocation,
  blobKey,
  workspaceID,
  tenantID,
  fileName,
  fileDescription,
  status,
  createdBy
) {
  const newErrors = {};

  if (!blobLocation) {
    newErrors.blobLocation = "Blob location is required";
  } else if (!/^https?:\/\/.*$/.test(blobLocation)) {
    newErrors.blobLocation = "Invalid URL";
  }

  if (!blobKey) {
    newErrors.blobKey = "Blob key is required";
  }

  if (!workspaceID) {
    newErrors.workspaceID = "Workspace ID is required";
  }

  if (!tenantID) {
    newErrors.tenantID = "Tenant ID is required";
  }

  if (!fileName) {
    newErrors.fileName = "File name is required";
  }

  if (!fileDescription) {
    newErrors.fileDescription = "File description is required";
  }

  if (!status) {
    newErrors.status = "Status is required";
  }

  if (!createdBy) {
    newErrors.createdBy = "Created by is required";
  }

  return newErrors;
}

export function validateUpdateResearchDataForm(
  id,
  blobLocation,
  blobKey,
  workspaceID,
  tenantID,
  fileName,
  fileDescription,
  status,
  createdBy
) {
  const newErrors = {};

  if (!id) {
    newErrors.id = "Research data ID is required";
  }

  if (!blobLocation) {
    newErrors.blobLocation = "Blob location is required";
  } else if (!/^https?:\/\/.*$/.test(blobLocation)) {
    newErrors.blobLocation = "Invalid URL";
  }

  if (!blobKey) {
    newErrors.blobKey = "Blob key is required";
  }

  if (!workspaceID) {
    newErrors.workspaceID = "Workspace ID is required";
  }

  if (!tenantID) {
    newErrors.tenantID = "Tenant ID is required";
  }

  if (!fileName) {
    newErrors.fileName = "File name is required";
  }

  if (!fileDescription) {
    newErrors.fileDescription = "File description is required";
  }

  if (!status) {
    newErrors.status = "Status is required";
  }

  if (!createdBy) {
    newErrors.createdBy = "Created by is required";
  }

  return newErrors;
}
