import React, { useState } from "react";
import "../dashboard.css";
import { Row, Form, Breadcrumb, CardBody, Image } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { Alert } from 'react-bootstrap';
import deleteIcon from '../assets/icons/delete.png';
import uploadIcon from '../assets/icons/upload.png';
import { Table, Nav } from 'react-bootstrap';
import editIcon from '../assets/icons/edit.png';

const AddDataReport = () => {
    const [image, setImage] = useState(null);
    const [error, setError] = useState(null);
    const maxSize = 10 * 1024 * 1024; // 10 MB
    // Function to handle file drop
    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0]; // Get the first file dropped
        handleFile(file);
    };
    // Function to handle file selection via Browse button
    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        handleFile(file);
    };
    // Function to validate and set the image
    const handleFile = (file) => {
        if (file && file.size <= maxSize && file.type.startsWith('image/')) {
            setImage(file);
            setError(null);
        } else {
            setError('File size exceeds 10 MB or invalid file type.');
        }
    };
    // Prevent default behavior for drag events
    const handleDragOver = (event) => {
        event.preventDefault();
    };
    // add product toggle functionality
    const [isExpanded, setIsExpanded] = useState(false);
    const handleAddProductClick = () => {
        setIsExpanded(!isExpanded); // Toggle between expanded and collapsed states
    };
    const handleSaveClick = () => {
        setIsExpanded(false); // Optionally, you can collapse it on Save
    };

    const products = [
        { name: "Product 1", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit", workspaceId: 26180, tenantId: 57400 },
        { name: "Product 2", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit", workspaceId: 26180, tenantId: 57400 },
    ];




    return (
        <>
            <div className="content page-wrapper">
                <div className="page-content">
                    <Row>
                        <Col lg={12}>
                            <Card className="outer-card">
                                <Card.Header className="bg-white border-0 p-0">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <h5 class="mb-0 fw-700">Data Report</h5>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body className="px-2">
                                    <Row>
                                        <Col lg={12}>
                                            <Form>
                                                <div className="form-inner position-relative p-3 mt-3 pt-4 shadow-none">
                                                    <Row>
                                                        <Col lg={12}>
                                                            <h5
                                                                style={{
                                                                    fontSize: "14px",
                                                                    background: "#fff",
                                                                    padding: "0 0px",
                                                                }}
                                                                className="text-12 mb-4 overlay-title"
                                                            >
                                                                Data Report Details
                                                            </h5>
                                                        </Col>
                                                        <Col lg={4} md={6}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label
                                                                    className="fw-500"
                                                                    style={{ fontSize: "14px" }}
                                                                >
                                                                    BLOB Location
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="BLOBLocation"
                                                                    className="custom-field"
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} md={6}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label
                                                                    className="fw-500"
                                                                    style={{ fontSize: "14px" }}
                                                                >
                                                                    BLOB Key
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="BLOBKey"
                                                                    className="custom-field"
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} md={6}>
                                                            <Form.Group className="mb-2">
                                                                <Form.Label
                                                                    className="fw-500"
                                                                    style={{ fontSize: "14px" }}
                                                                >
                                                                    Workspace ID
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="Workspace ID"
                                                                    className="custom-field"
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} md={6}>
                                                            <Form.Group className="mb-2">
                                                                <Form.Label
                                                                    className="fw-500"
                                                                    style={{ fontSize: "14px" }}
                                                                >
                                                                    Tenant ID
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="Tenant ID"
                                                                    className="custom-field"
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} md={6}>
                                                            <Form.Group className="mb-2">
                                                                <Form.Label
                                                                    className="fw-500"
                                                                    style={{ fontSize: "14px" }}
                                                                >
                                                                    File Name
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="fileName"
                                                                    className="custom-field"
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} md={6}>
                                                            <Form.Group className="mb-2">
                                                                <Form.Label
                                                                    className="fw-500"
                                                                    style={{ fontSize: "14px" }}
                                                                >
                                                                   File Description
                                                                </Form.Label>
                                                                <Form.Group controlId="formTextarea">
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        className="custom-field overflow-hidden"
                                                                        rows={1}
                                                                        placeholder=""
                                                                    />
                                                                </Form.Group>
                                                            </Form.Group>
                                                        </Col>

                                                    </Row>
                                                </div>
                                                <Row className="mt-4">
                                                    <Col lg={12} className="add-product-footer-btn text-end">
                                                        <Button className="btn-light me-2">Cancel</Button>
                                                        <Button
                                                            style={{ fontSize: "14px" }}
                                                            className="btn"
                                                        >
                                                            Save
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>



        </>
    );
};
export default AddDataReport;