import React, { useEffect, useState } from "react";
import "./Sidebar.css"; // Import some basic styles
import logo from "../../../assets/w-logo.png";
import { Image } from "react-bootstrap";
import sidbarFooterImage from "../../../assets/shape-sidebar.png";
import roles from "../../../request/role";
import { decryptData } from "../../../services/authService";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [role, setRole] = useState(roles.MEMBER);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    fetchRole();
  }, []);

  const fetchRole = () => {
    let fetchedrole = localStorage.getItem("role");
    let decryptedRole = decryptData(fetchedrole);
    setRole(decryptedRole);
  };

  return (
    <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
      <div className="sidebar-header">
        <div className="logo">
          <Image src={logo} alt="Logo" />
        </div>
      </div>
      <nav className="menu">
        {/* <ul>
          <li>
            <i onClick={toggleSidebar}>Menu</i>
          </li>
        </ul> */}
        {isOpen && (
          <ul className="sidebar-menus">
            <li className="menu-label">
              <div className="menu-divider">
                <span>Menu</span>
              </div>
            </li>

            {role === roles.MEMBER && (
              <li className="active">
                <a href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11 20H10.5C6.74142 20 4.86213 20 3.60746 19.0091C3.40678 18.8506 3.22119 18.676 3.0528 18.4871C2 17.3062 2 15.5375 2 12C2 8.46252 2 6.69377 3.0528 5.5129C3.22119 5.32403 3.40678 5.14935 3.60746 4.99087C4.86213 4 6.74142 4 10.5 4H13.5C17.2586 4 19.1379 4 20.3925 4.99087C20.5932 5.14935 20.7788 5.32403 20.9472 5.5129C21.8957 6.57684 21.9897 8.11799 21.999 11"
                      stroke="#093B60"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2 9H22"
                      stroke="#093B60"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 18C14 18 15 18 16 20C16 20 19.1765 15 22 14"
                      stroke="#D7282F"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  My Subscription
                </a>
              </li>
            )}
            {(role === roles.ADMIN || role === roles.SUPERADMIN) && (
              <>
                <li className="active">
                  <a href="/ReportConfiguration">Report Configuration</a>
                </li>
                <li className="inactive">
                  <a href="/DataReport">Research Data</a>
                </li>
                <li className="inactive">
                  <a href="/BiIntegration">BI Integration</a>
                </li>
              </>
            )}
            {role === roles.SUPERADMIN && (
              <>
                <li className="inactive">
                  <a href="/ManageAdmins">Manage Admins</a>
                </li>
              </>
            )}
            <li className="menu-label">
              <div className="menu-divider">
                <span>Settings</span>
              </div>
            </li>

            <li className="inactive">
              <a href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7.78256 17.1112C6.68218 17.743 3.79706 19.0331 5.55429 20.6474C6.41269 21.436 7.36872 22 8.57068 22H15.4293C16.6313 22 17.5873 21.436 18.4457 20.6474C20.2029 19.0331 17.3178 17.743 16.2174 17.1112C13.6371 15.6296 10.3629 15.6296 7.78256 17.1112Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.5 10C15.5 11.933 13.933 13.5 12 13.5C10.067 13.5 8.5 11.933 8.5 10C8.5 8.067 10.067 6.5 12 6.5C13.933 6.5 15.5 8.067 15.5 10Z"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M2.854 16C2.30501 14.7664 2 13.401 2 11.9646C2 6.46129 6.47715 2 12 2C17.5228 2 22 6.46129 22 11.9646C22 13.401 21.695 14.7664 21.146 16"
                    stroke="#D7282F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>{" "}
                Profile Settings
              </a>
            </li>
          </ul>
        )}
      </nav>
      <div className="logout" style={{}}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M7.02331 5.5C4.59826 7.11238 3 9.86954 3 13C3 17.9706 7.02944 22 12 22C16.9706 22 21 17.9706 21 13C21 9.86954 19.4017 7.11238 16.9767 5.5"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 2V10"
            stroke="#D7282F"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>{" "}
        <span>Logout</span>
      </div>
      <div className="sidebar-footer-image">
        <Image src={sidbarFooterImage} alt="sidbar Footer Image" />
      </div>
    </div>
  );
};

export default Sidebar;
