import axios from "axios";
import {
  GET_USER_BY_ID_URL,
  LIST_USER_URL,
  GET_USER_BY_EMAIL_URL,
} from "./apiConfig";
import authHeader from "./AuthHeader";
class UserService {
  getUserById(id) {
    return axios.post(GET_USER_BY_ID_URL, { id }).then((response) => {
      return response.data;
    });
  }

  getUserByEmail(email) {
    return axios
      .post(
        GET_USER_BY_EMAIL_URL,
        { email },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  listUsers(pageSize, pageNo) {
    return axios.post(LIST_USER_URL, { pageSize, pageNo }).then((response) => {
      return response.data;
    });
  }
}

export default new UserService();
