import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../assets/logo.png";
import backIcon from "../assets/icons/backIcon.svg";
import "../login.css";
import "../main.css";
const Contact = () => {




    return (
        <div id="main-wrapper" className="login authentication-page">
            <Container fluid className="px-0">
                <Row className="g-0 min-vh-100">
                    <Col md={6} lg={5} className="d-flex flex-column align-items-center">
                        <Container className="pt-4">
                            <Row className="g-0">
                                <Col xs={11} md={10} lg={9} className="mx-auto">
                                    <div className="logo">
                                        <a
                                            className="fw-600 text-6 text-dark link-underline link-underline-opacity-0"
                                            href="index.html"
                                            title="Dubai Chambers"
                                        >
                                            <Image src={logo} alt="Logo" />
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                        <Container className="my-auto py-5">
                            <Row className="g-0">
                            
                                <Col xs={11} md={10} lg={9} className="mx-auto">
                                    <h5 className="d-flex align-items-center mb-3" style={{cursor:"pointer"}} onClick={() => window.history.back()}><Image className="me-1" src={backIcon} style={{width:'18'}} alt="back"/>Back</h5>
                                    <div className='form-inner'>
                                        <h1 className="text-12 mb-4 text-center">Contact Us</h1>
                                        <Form id="loginForm">
                                            <Form.Group controlId="emailAddress">
                                                <Form.Label className="fw-500">Your Email Address</Form.Label>
                                                <div className="mb-3 icon-group icon-group-end">
                                                    <Form.Control
                                                        type="email"
                                                        className="bg-light border-light"
                                                        placeholder="a@example.com"
                                                        required
                                                    />
                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="formTextarea">
                                                <Form.Label className="fw-500">Comment</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    placeholder=""
                                                />
                                            </Form.Group>



                                            <div className="d-grid my-4">
                                                <Button type="submit" className="btn-dark btn-lg">
                                                    Submit
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col md={6} lg={7} className="bg-light">
                        <div className="hero-wrap d-flex align-items-center h-100">
                            <div className="login-image-container hero-bg hero-bg-scroll">
                            <div className="fixed-btn">
                  <Button className="btn btn-red">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                        stroke="white"
                        stroke-width="1.5"
                      />
                      <path
                        d="M10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9C14 9.39815 13.8837 9.76913 13.6831 10.0808C13.0854 11.0097 12 11.8954 12 13V13.5"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M11.9919 17H12.0009"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>{" "}
                    Help
                  </Button>
                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Contact;
