import React, { useEffect, useState } from "react";
import "../dashboard.css";
import { Row, Form, Breadcrumb } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { InputGroup, FormControl, Image } from "react-bootstrap";
import editIcon from "../assets/icons/edit.png";
import UserService from "../services/userService";
import { getUserByIdRequestBody } from "../request/requestBodies";

const AccountProfile = () => {
  const [user, setUser] = useState("");

  const email = localStorage.getItem("email");
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const user = await UserService.getUserByEmail(email)
      .then((res) => {
        console.log("profile response", res);
        if (res && res.data && res.data.data) {
          setFormValues({
            firstName: res.data.data.firstName || "",
            lastName: res.data.data.lastName || "",
            email: res.data.data.email || "",
            phone: res.data.data.phoneNumber || "",
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // State to manage whether the form is editable or not
  const [isEditable, setIsEditable] = useState(false);

  // State to store form field values
  const [formValues, setFormValues] = useState({
    firstName: "Kiran",
    lastName: "Bokde",
    email: "Kin@gmail.com",
    phone: "8574578414",
  });

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // Cancel form editing
  const handleCancelClick = () => {
    setIsEditable(false);
  };

  // Save form changes
  const handleSaveClick = () => {
    console.log("Form submitted:", formValues);
    setIsEditable(false);
  };

  // Function to handle the toggle of the editable state
  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };

  const handleSubmit = ()=>{
    
  }

  return (
    <>
      <div className="content page-wrapper">
        <div className="page-content">
          <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <Breadcrumb className="mb-0">
              <Breadcrumb.Item className="fw-700">
                <strong>Personal Information</strong>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Form >
                  <Row>
                    <Col lg={8} md={8}>
                      <Row>
                        <Col lg={12}>
                          <div class="d-flex align-items-center gap-x-4 mb-3">
                            <div className="profile-name">
                              <span>KB</span>
                            </div>
                            <div>
                              <h5 class="text-base font-bold fw-500 ms-3">
                                {formValues.firstName} {formValues.lastName}
                              </h5>
                            </div>
                          </div>
                        </Col>
                        <Col lg={6} md={6}>
                          <Form.Label
                            className="fw-500"
                            style={{ fontSize: "14px" }}
                          >
                            First Name
                          </Form.Label>

                          <InputGroup className="mb-3 custom-group">
                            <FormControl
                              value={formValues.firstName}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              type="text"
                              name="firstName"
                              className="custom-field custom-group-field"
                              placeholder="First Name"
                            />
                            {isEditable && (
                              <InputGroup.Text
                                onClick={handleEditClick}
                                style={{ cursor: "pointer" }}
                              >
                                <Image
                                  style={{ width: "14px" }}
                                  src={editIcon}
                                />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>

                        <Col lg={6} md={6}>
                          <Form.Label
                            className="fw-500"
                            style={{ fontSize: "14px" }}
                          >
                            Last Name
                          </Form.Label>

                          <Form.Group className="mb-2">
                            <InputGroup className="mb-3 custom-group">
                              <FormControl
                                value={formValues.lastName}
                                onChange={handleInputChange}
                                disabled={!isEditable}
                                type="text"
                                name="firstName"
                                className="custom-field custom-group-field"
                                placeholder="First Name"
                              />
                              {isEditable && (
                                <InputGroup.Text
                                  onClick={handleEditClick}
                                  style={{ cursor: "pointer" }}
                                >
                                  <Image
                                    style={{ width: "14px" }}
                                    src={editIcon}
                                  />
                                </InputGroup.Text>
                              )}
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col lg={6} md={6}>
                          <Form.Label
                            className="fw-500"
                            style={{ fontSize: "14px" }}
                          >
                            Email
                          </Form.Label>

                          <InputGroup className="mb-3 custom-group">
                            <Form.Control
                              type="email"
                              name="email"
                              className="custom-field"
                              placeholder="Email"
                              value={formValues.email}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                            />
                            {isEditable && (
                              <InputGroup.Text
                                onClick={handleEditClick}
                                style={{ cursor: "pointer" }}
                              >
                                <Image
                                  style={{ width: "14px" }}
                                  src={editIcon}
                                />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>
                        <Col lg={6} md={6}>
                          <Form.Label
                            className="fw-500"
                            style={{ fontSize: "14px" }}
                          >
                            Phone
                          </Form.Label>

                          <InputGroup className="mb-3 custom-group">
                            <Form.Control
                              type="text"
                              name="phone"
                              className="custom-field"
                              placeholder="Phone"
                              value={formValues.phone}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                            />
                            {isEditable && (
                              <InputGroup.Text
                                onClick={handleEditClick}
                                style={{ cursor: "pointer" }}
                              >
                                <Image
                                  style={{ width: "14px" }}
                                  src={editIcon}
                                />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>

                        {/* <Col lg={6} md={6}>
                      <div className="my-4 mb-2">
                        {!isEditable ? (
                          <Button style={{ fontSize: '14px' }} onClick={handleEditClick} className="btn">
                            <svg style={{ marginRight: '5px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                              <path d="M16.9459 3.17305C17.5332 2.58578 17.8268 2.29215 18.1521 2.15173C18.6208 1.94942 19.1521 1.94942 19.6208 2.15173C19.946 2.29215 20.2397 2.58578 20.8269 3.17305C21.4142 3.76032 21.7079 4.05395 21.8483 4.37925C22.0506 4.8479 22.0506 5.37924 21.8483 5.84789C21.7079 6.17319 21.4142 6.46682 20.8269 7.05409L15.8054 12.0757C14.5682 13.3129 13.9496 13.9315 13.1748 14.298C12.4 14.6645 11.5294 14.7504 9.78823 14.9222L9 15L9.07778 14.2118C9.24958 12.4706 9.33549 11.6 9.70201 10.8252C10.0685 10.0504 10.6871 9.43183 11.9243 8.19464L16.9459 3.17305Z" stroke="white" stroke-width="1.5" stroke-linejoin="round" />
                              <path d="M6 15H3.75C2.7835 15 2 15.7835 2 16.75C2 17.7165 2.7835 18.5 3.75 18.5H13.25C14.2165 18.5 15 19.2835 15 20.25C15 21.2165 14.2165 22 13.25 22H11" stroke="#D7282F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg> Edit
                          </Button>
                        ) : (
                          <>
                            <Button onClick={handleCancelClick} className="btn-light me-2">
                              Cancel
                            </Button>
                            <Button style={{ fontSize: '14px' }} onClick={handleSaveClick} className="btn">
                              Save
                            </Button>
                          </>
                        )}
                      </div>
                      </Col> */}
                        <Col lg={6} md={6}>
                          <div className="mt-2 mb-2">
                            <Button
                              onClick={handleCancelClick}
                              className="btn-light me-2"
                            >
                              Cancel
                            </Button>

                            {isEditable ? (
                              <Button
                                style={{ fontSize: "14px" }}
                                onClick={handleSaveClick}
                                className="btn"
                              >
                                Save
                              </Button>
                            ) : (
                              <Button
                                style={{ fontSize: "14px" }}
                                onClick={handleEditClick}
                                className="btn"
                              >
                                Edit
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="card-title-danger d-flex align-items-center mb-3 mt-4">
                <h3 className="outer-card-title">Change Password</h3>
              </div>
            </Col>
            <Col lg={12}>
              <Card className="outer-card">
                <Form>
                  <Row>
                    <Col lg={8} md={8}>
                      <Row>
                        <Col lg={6} md={6}>
                          <Form.Label
                            className="fw-500"
                            style={{ fontSize: "14px" }}
                          >
                            Current Password
                          </Form.Label>

                          <InputGroup className="mb-3 custom-group">
                            <FormControl
                              type="password"
                              name="currentPassword"
                              className="custom-field custom-group-field"
                            />
                          </InputGroup>
                        </Col>
                        <Col lg={6} md={6}>
                          <Form.Label
                            className="fw-500"
                            style={{ fontSize: "14px" }}
                          >
                            New Password
                          </Form.Label>

                          <InputGroup className="mb-3 custom-group">
                            <FormControl
                              type="password"
                              name="newPassword"
                              className="custom-field custom-group-field"
                            />
                          </InputGroup>
                        </Col>
                        <Col lg={6} md={6}>
                          <Form.Label
                            className="fw-500"
                            style={{ fontSize: "14px" }}
                          >
                            Confirm Password
                          </Form.Label>

                          <InputGroup className="mb-3 custom-group">
                            <FormControl
                              type="password"
                              name="confirmPassword"
                              className="custom-field custom-group-field"
                            />
                          </InputGroup>
                        </Col>
                        <Col lg={12} md={12}>
                          <div className="mt-2 mb-2">
                            <Button className="btn-light me-2">Cancel</Button>
                            <Button
                              style={{ fontSize: "14px" }}
                              className="btn"
                            >
                              Save
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AccountProfile;
