import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../assets/logo.png";
import "../login.css";
import "../main.css";
import AuthService from "../services/authService";
import { validateForgotPasswordForm as ValidateForgotPasswordForm } from "../request/validationFunctions";

const ForgotPassword = () => {
  const [emailId, setEmailId] = useState("");
  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = ValidateForgotPasswordForm(emailId);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
         const apiResponse = await AuthService.forgotPasswordSendOTP(emailId);
         console.log("apiResponse",apiResponse.statusCode)
        alert("Verification link Sent!", emailId);
        setErrors({});
        setFormSubmitted(true);
      window.location.href="/ResetPassword/"+emailId
        console.log("Forgot password form submitted", { emailId });
      } catch (error) {}
    }
  };

  return (
    <div id="main-wrapper" className="login authentication-page">
      <Container fluid className="px-0">
        <Row className="g-0 min-vh-100">
          <Col md={6} lg={5} className="d-flex flex-column align-items-center">
            <Container className="pt-4">
              <Row className="g-0">
                <Col xs={11} md={10} lg={9} className="mx-auto">
                  <div className="logo">
                    <a
                      className="fw-600 text-6 text-dark link-underline link-underline-opacity-0"
                      href="index.html"
                      title="Dubai Chambers"
                    >
                      <Image src={logo} alt="Logo" />
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>

            <Container className="my-auto py-5">
              <Row className="g-0">
                <Col xs={11} md={10} lg={9} className="mx-auto">
                  <div className="form-inner">
                    <h1 className="text-12 mb-4 text-center">
                      Forgot password?
                    </h1>
                    <p className="text-center mb-4">
                      Please enter the email associated with your account and
                      you will get a reset password link.
                    </p>
                    <Form id="forgotPasswordForm" onSubmit={handleSubmit}>
                      <Form.Group controlId="forgotPassword">
                        <div className="mb-3 icon-group icon-group-end">
                          <Form.Control
                            type="email"
                            className="bg-white"
                            placeholder="a@example.com"
                            value={emailId}
                            onChange={(e) => setEmailId(e.target.value)}
                            isInvalid={!!errors.emailId}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.emailId}
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>

                      <div className="d-grid mt-5 mb-2">
                        <Button
                          type="submit"
                          className="btn-secondary btn btn-lg"
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                    {formSubmitted && (
                      <p className="text-center text-success mt-3">
                        If this email exists, a reset link has been sent.
                      </p>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md={6} lg={7} className="bg-light">
            <div className="hero-wrap d-flex align-items-center h-100">
              <div className="login-image-container hero-bg hero-bg-scroll"></div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPassword;
