import axios from "axios";
import { 
  CREATE_PRODUCT_URL, 
  GET_PRODUCT_BY_ID_URL, 
  UPDATE_PRODUCT_URL, 
  DELETE_PRODUCT_URL, 
  LIST_PRODUCTS_URL 
} from "./apiConfig";
import authHeader from "./AuthHeader";

class ProductService {
  createProduct(productData) {
    return axios.post(CREATE_PRODUCT_URL, productData).then((response) => {
      return response.data;
    });
  }

  getProductById(id) {
    return axios.post(GET_PRODUCT_BY_ID_URL, { id }).then((response) => {
      return response.data;
    });
  }

  updateProduct(productData) {
    return axios.put(UPDATE_PRODUCT_URL, productData).then((response) => {
      return response.data;
    });
  }

  deleteProduct(id) {
    return axios.delete(DELETE_PRODUCT_URL.replace("{id}", id)).then((response) => {
      return response.data;
    });
  }

  listProducts() {
    return axios.get(LIST_PRODUCTS_URL,{
      headers: authHeader()
    }).then((response) => {
      return response.data;
    });
  }
}

export default new ProductService();
